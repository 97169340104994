import React, { useCallback, useMemo, useRef, useState } from "react"
import type { IPanariskWidget, IPanariskWidgetProps } from "../models/IPanariskWidgetProps"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField,
  type Theme,
  Typography,
  useTheme,
} from "@mui/material"
import PaperLocal from "../../containers/PaperLocal"
import { axiosInstance, BASE_URL, urlSearchParams } from "../../../utilities/request_utility"
import { useAxiosRequest } from "../../../hooks/useAxiosRequest"
import ViewLoading from "../../ViewLoading"
import ErrorMessage from "../../ErrorMessage"
import useSaveChart from "../hooks/useSaveChart"
import { type IStats } from "../../../models/service/IStats"
import WidgetPieChart from "../charts/WidgetPieChart"
import WidgetMenu from "../components/WidgetMenu"
import WidgetBarChart from "../charts/WidgetBarChart"
import DialogControls from "../../DialogControls"
import WidgetBarHorizontalChart from "../charts/WidgetBarHorizontalChart"
import { type IPaging } from "../../../models/components/IPaging"
import { nameToLabel } from "../../../utilities/form_utility"
import { type IAvailableModel, type IChartBuilderState } from "../models/IStatBuilderState"
import useProfileStorage from "../../../hooks/useProfileStorage"
import useEffectAsync from "../../../hooks/useEffectAsync"
import { useNavigate } from "react-router-dom"
import { INSPECTION_RECOMMENDATION_INDEX_URL } from "../../../../apps/admin/config/urls"
import { type IFilter } from "../../../models/components/IFilter"
import InfoValueCard from "../../display/InfoValueCard"
import { RestRepository } from "../../../repositories/RestRepository"
import { FILTER_BOOKMARK_ENDPOINT, type IFilterBookmark } from "../../../models/service/IFilterBookmark"
import type { IListItem } from "../../../models/components/IListItem"
import SelectFilteredSingle from "../../forms/SelectFilteredSingle"

export const filterBookmarkRepository = new RestRepository<IListItem>(FILTER_BOOKMARK_ENDPOINT)

export const CHART_BUILDER_KEY = "CHART_BUILDER_KEY"

interface IRelatedModelInfo {
  id: number
  name: string
  model_name: string
}

export const CHART_FILTER_TITLE = "Chart Filter"

/**
 * Filters the provided model based on its `core_model_name` attribute and returns a corresponding array of filter objects.
 *
 * @param {string | undefined} modelName - The model to filter, which may have a `core_model_name` property.
 * @returns {IFilter[] | undefined} An array of filter objects if the `core_model_name` is recognized, otherwise `undefined`.
 */
const filterBookmarkModel = (modelName: string | undefined): IFilter[] | undefined => {
  if (modelName === "LocationImpairment") {
    return [{ field: "model_name", value: "location_impairment" }]
  }
  if (modelName === "InspectionRecommendation") {
    return [{ field: "model_name", value: "inspection_recommendation" }]
  }
  if (modelName === "Location") {
    return [{ field: "model_name", value: "location" }]
  }
  if (modelName === "Inspection") {
    return [{ field: "model_name", value: "inspection" }]
  }
}

/**
 * Generates a storage key based on the given ID.
 *
 * @param {string} id - The ID used to generate the storage key.
 * @returns {string} The generated storage key.
 */
const storageKey = (id: string): string => {
  return `${CHART_BUILDER_KEY}-${id}`
}

const title = "Chart Builder"
const description = "Build custom stats with charts."

/**
 * Returns the default state for a chart builder configuration.
 *
 * @param {Theme} theme - The theme object containing palette and styling properties.
 * @returns {IChartBuilderState} The default state object configured for the chart builder.
 */
const defaultState = (theme: Theme): IChartBuilderState => ({
  title,
  description,
  modelName: "InspectionRecommendation",
  groupBy: "type",
  aggregator: "Count",
  aggregatorField: null,
  chartType: "bar",
  color: theme.palette.info.main,
  icon: "👍",
  size: 6,
  limit: 5,
  direction: "desc",
})

/**
 * ChartBuilderWidget is a functional component that displays a widget for displaying recommendation types statistics.
 *
 * @param {IPanariskWidgetProps} props - The props object containing the id of the widget.
 * @returns {React.ReactElement} - The rendered widget component.
 */
const ChartBuilderWidget: IPanariskWidget = (props: IPanariskWidgetProps): React.ReactElement => {
  const { id, onMoveUp, onMoveDown, onDelete } = props
  const initialLoad = useRef<boolean>(false)
  const theme = useTheme()

  const profileStorage = useProfileStorage<IChartBuilderState | null>(storageKey(id), null)
  const [chartBuilderState, setChartBuilderState] = profileStorage.data

  const [cbState, setCbState] = useState<IChartBuilderState | null>(null)

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [availableModels, setAvailableModels] = useState<IAvailableModel[]>([])

  const { callRequest, loading, errorMessage, error } = useAxiosRequest()
  const [data, setData] = useState<IStats[]>([])
  const [singleValue, setSingleValue] = useState<number | undefined>()
  const navigate = useNavigate()

  const { chartError, chartRef, handleChartExport } = useSaveChart()

  const handleLoadAvailableModels = useCallback(async () => {
    await callRequest(async () => {
      const url = `${BASE_URL}/stats/models_available/`
      const response = await axiosInstance.get<IAvailableModel[]>(url)
      setAvailableModels(response.data)
    })
  }, [])

  const handleLoadData = useCallback(async (chartBuilderState1: IChartBuilderState | null) => {
    if (chartBuilderState1 !== null) {
      await callRequest(async () => {
        const postData: IFilter[] = [
          {
            field: "model_name",
            value: chartBuilderState1.modelName,
          },
          {
            field: "aggregator",
            value: chartBuilderState1.aggregator,
          },
          {
            field: "aggregator_field",
            value: chartBuilderState1.aggregatorField?.name,
          },
        ]
        if (chartBuilderState1.chartType === "single_value") {
          let paging: IPaging = { ordering: { field: "total" }, ...chartBuilderState1.filterBookmark?.paging }
          paging = { ...paging, filters: [...(paging.filters ?? []), ...postData] }
          const queryString = urlSearchParams(paging)
          const url = `${BASE_URL}/stats/model_aggregator/${queryString}`
          const singleResponse = await axiosInstance.get<Record<string, number>>(url)
          setSingleValue(singleResponse.data.total)
        } else {
          const postData: IFilter[] = [
            {
              field: "model_name",
              value: chartBuilderState1.modelName,
            },
            {
              field: "group_by",
              value: chartBuilderState1.groupBy,
            },
            {
              field: "aggregator",
              value: chartBuilderState1.aggregator,
            },
            {
              field: "aggregator_field",
              value: chartBuilderState1.aggregatorField?.name,
            },
          ]
          const limit = chartBuilderState1.limit
          const direction = chartBuilderState1.direction
          const bookmark = chartBuilderState1.filterBookmark?.paging

          let paging: IPaging = { ...bookmark, limit, ordering: { field: "total", direction } }
          paging = { ...paging, filters: [...(paging.filters ?? []), ...postData] }
          const queryString = urlSearchParams(paging)
          const url = `${BASE_URL}/stats/model_aggregator/${queryString}`
          const response = await axiosInstance.get<IStats[]>(url)
          const data1 = response.data.map(data1 => ({ value: data1.value, label: data1.label }))
          setData(data1)
        }
      })
    }
  }, [])

  const handleCloseDialog = useCallback(async () => {
    setOpenDialog(false)
    setData([])
    await setChartBuilderState(cbState)
    await handleLoadData(cbState)
  }, [handleLoadData, cbState])

  const handleOpenDialog = useCallback(async () => {
    setOpenDialog(true)
    await handleLoadAvailableModels()
    setCbState(chartBuilderState)
  }, [handleLoadAvailableModels, chartBuilderState])

  const handleChangeState = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.name === "aggregatorField") {
        setCbState(cbState1 => {
          const model = availableModels.find(am => am.core_model_name === cbState1?.modelName)
          if (cbState1 === null) {
            return {
              ...defaultState(theme),
              aggregatorField: model?.aggregator_field.find(af => af.name === event.target.value) ?? null,
            }
          }
          return {
            ...cbState1,
            aggregatorField: model?.aggregator_field.find(af => af.name === event.target.value) ?? null,
          }
        })
      } else {
        setCbState(cbState1 => {
          if (cbState1 === null) {
            return {
              ...defaultState(theme),
              [event.target.name]: event.target.value,
            }
          }
          return { ...cbState1, [event.target.name]: event.target.value }
        })
      }
    },
    [availableModels],
  )

  const handleFilterBookmark = useCallback(async (item: IListItem | null) => {
    if (item !== null) {
      const filterBookmark1 = (await filterBookmarkRepository.read(item.id)) as IFilterBookmark
      setCbState(cbState1 => {
        if (cbState1 !== null) {
          return { ...cbState1, filterBookmark: filterBookmark1 }
        }
        return null
      })
    } else {
      setCbState(cbState1 => {
        if (cbState1 !== null) {
          return { ...cbState1, filterBookmark: null }
        }
        return cbState1
      })
    }
  }, [])

  useEffectAsync(async () => {
    if (
      data.length === 0 &&
      chartBuilderState !== null &&
      JSON.stringify(chartBuilderState) !== JSON.stringify(defaultState) &&
      !initialLoad.current
    ) {
      initialLoad.current = true
      await handleLoadData(chartBuilderState)
      await handleLoadAvailableModels()
    }
  }, [handleLoadData, data, initialLoad, chartBuilderState])

  const handleSave = useCallback(async () => {
    if (chartBuilderState !== null) {
      await setChartBuilderState({ ...defaultState(theme) })
    }
  }, [chartBuilderState])

  const currentModel = useMemo(() => {
    return availableModels?.find(am => am.core_model_name === cbState?.modelName)
  }, [availableModels, cbState?.modelName])

  const handleNavigation = useCallback(
    async (name: string) => {
      if (chartBuilderState !== null) {
        const model = chartBuilderState.modelName
        const field = chartBuilderState.groupBy

        await callRequest(async () => {
          const paging: IPaging = {
            filters: [
              { field: "model", value: model },
              { field: "field", value: field },
              { field: "name", value: name },
            ],
          }
          const queryString = urlSearchParams(paging)
          const url = `${BASE_URL}/stats/lookup_filter_id_by_name/${queryString}`
          const { data } = await axiosInstance.get<IRelatedModelInfo>(url)

          const filter: IFilter = {
            field,
            value: data.id,
            title: `${CHART_FILTER_TITLE} for ${nameToLabel(field)}`,
            display: name,
          }
          if (data.model_name === "InspectionRecommendation") {
            navigate(INSPECTION_RECOMMENDATION_INDEX_URL, { state: filter })
          }
        })
      }
    },
    [chartBuilderState],
  )

  const handleRefresh = useCallback(async () => {
    await handleLoadData(chartBuilderState)
  }, [handleLoadData, chartBuilderState])

  const widgetMenu = useMemo(() => {
    return (
      <WidgetMenu>
        <MenuItem onClick={handleSave}>Clear State</MenuItem>
        <MenuItem onClick={handleRefresh}>Refresh</MenuItem>
        <Divider />
        <MenuItem onClick={handleChartExport()}>Save Chart</MenuItem>
        <Divider />
        <MenuItem onClick={onMoveUp}>Move Up</MenuItem>
        <MenuItem onClick={onMoveDown}>Move Down</MenuItem>
        <Divider />
        <MenuItem onClick={onDelete}>Delete Widget</MenuItem>
        <Divider />
        <MenuItem onClick={handleOpenDialog}>Options...</MenuItem>
      </WidgetMenu>
    )
  }, [handleSave, handleLoadData, handleChartExport, onMoveUp, onMoveDown, onDelete, handleOpenDialog])

  return (
    <Grid item xs={12} lg={chartBuilderState?.size ?? 6}>
      <PaperLocal noPaper={chartBuilderState?.chartType === "single_value"}>
        {chartBuilderState?.chartType !== "single_value" && (
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <h4>{chartBuilderState?.title}</h4>
            </Grid>
            <Grid item xs>
              <ViewLoading inline loading={loading} />
            </Grid>
            <Grid item>{widgetMenu}</Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{chartBuilderState?.description}</Typography>
            </Grid>
          </Grid>
        )}

        {error && <ErrorMessage error={errorMessage} />}
        {chartError !== undefined && <ErrorMessage error={chartError} />}
        {data.length > 0 && (
          <>
            {chartBuilderState?.chartType === "pie" && (
              <WidgetPieChart
                chartRef={chartRef}
                data={data}
                statBuilderStat={chartBuilderState}
                onClick={handleNavigation}
              />
            )}
            {chartBuilderState?.chartType === "bar" && (
              <WidgetBarChart
                chartRef={chartRef}
                data={data}
                statBuilderStat={chartBuilderState}
                onClick={handleNavigation}
              />
            )}
            {chartBuilderState?.chartType === "bar_horizontal" && (
              <WidgetBarHorizontalChart
                chartRef={chartRef}
                data={data}
                statBuilderStat={chartBuilderState}
                onClick={handleNavigation}
              />
            )}
          </>
        )}
        {chartBuilderState?.chartType === "single_value" && (
          <>
            <Box sx={{ position: "absolute" }}>{widgetMenu}</Box>
            <InfoValueCard
              title={chartBuilderState?.title}
              value={singleValue ?? 0}
              color={chartBuilderState.color ?? theme.palette.info.main}
              icon={chartBuilderState.icon}
            />
          </>
        )}
      </PaperLocal>
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>Chart Builder Options</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Title"
                    name="title"
                    value={cbState?.title ?? ""}
                    onChange={handleChangeState}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    label="Chart Type"
                    name="chartType"
                    value={cbState?.chartType ?? ""}
                    onChange={handleChangeState}>
                    <MenuItem value="pie">Pie Chart</MenuItem>
                    <MenuItem value="bar">Bar Chart</MenuItem>
                    <MenuItem value="bar_horizontal">Horizontal Bar Chart</MenuItem>
                    <MenuItem value="single_value">Single Value</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    label="Width"
                    name="size"
                    value={cbState?.size ?? ""}
                    onChange={handleChangeState}>
                    <MenuItem value={3}>Small</MenuItem>
                    <MenuItem value={6}>Medium</MenuItem>
                    <MenuItem value={12}>Large</MenuItem>
                  </TextField>
                </Grid>
                {cbState?.chartType !== "single_value" && (
                  <>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        select
                        label="Limit"
                        name="limit"
                        value={cbState?.limit ?? ""}
                        onChange={handleChangeState}>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        select
                        label="Order"
                        name="direction"
                        value={cbState?.direction ?? ""}
                        onChange={handleChangeState}>
                        <MenuItem value="asc">Ascending</MenuItem>
                        <MenuItem value="desc">Descending</MenuItem>
                      </TextField>
                    </Grid>
                  </>
                )}
                {cbState?.chartType === "single_value" && (
                  <>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        type="color"
                        label="Color"
                        name="color"
                        value={cbState.color ?? ""}
                        onChange={handleChangeState}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        label="Icon"
                        name="icon"
                        value={cbState.icon ?? ""}
                        onChange={handleChangeState}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    value={cbState?.description ?? ""}
                    onChange={handleChangeState}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    label="Source"
                    name="modelName"
                    value={cbState?.modelName ?? ""}
                    onChange={handleChangeState}>
                    {availableModels?.map(am => {
                      return (
                        <MenuItem key={am.core_model_name} value={am.core_model_name}>
                          {am.core_model_title}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <SelectFilteredSingle
                    name="filter_bookmark"
                    defaultValue={cbState?.filterBookmark}
                    repository={filterBookmarkRepository}
                    filters={filterBookmarkModel(cbState?.modelName)}
                    onChange={handleFilterBookmark}
                  />
                </Grid>
                {currentModel !== undefined &&
                  currentModel.group_by.length > 0 &&
                  cbState?.chartType !== "single_value" && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        select
                        label="Group By"
                        name="groupBy"
                        value={cbState?.groupBy ?? ""}
                        onChange={handleChangeState}>
                        {currentModel.group_by.map(groupBy => (
                          <MenuItem key={groupBy} value={groupBy}>
                            {nameToLabel(groupBy)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                {currentModel !== undefined && currentModel.group_by.length > 0 && (
                  <Grid item xs={12} lg={6}>
                    <TextField
                      fullWidth
                      select
                      label="Aggregator"
                      name="aggregator"
                      value={cbState?.aggregator ?? ""}
                      onChange={handleChangeState}>
                      <MenuItem value="Count">Count</MenuItem>
                      <MenuItem value="Sum">Sum</MenuItem>
                      <MenuItem value="Avg">Avg</MenuItem>
                      <MenuItem value="Min">Min</MenuItem>
                      <MenuItem value="Max">Max</MenuItem>
                    </TextField>
                  </Grid>
                )}
                {currentModel !== undefined && currentModel.group_by.length > 0 && (
                  <Grid item xs={12} lg={6}>
                    <TextField
                      fullWidth
                      select
                      label="Aggregator Field"
                      name="aggregatorField"
                      value={cbState?.aggregatorField?.name ?? ""}
                      onChange={handleChangeState}>
                      {currentModel.aggregator_field.map(aggregatorField => (
                        <MenuItem key={aggregatorField.name} value={aggregatorField.name}>
                          {aggregatorField.units_pre} {nameToLabel(aggregatorField.name)} {aggregatorField.units_post}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleCloseDialog} buttonLabel="Done" />
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

ChartBuilderWidget.nameId = "chart_builder"
ChartBuilderWidget.title = title
ChartBuilderWidget.description = description

export default ChartBuilderWidget
