import React, { useContext } from "react"
import { Container, Grid } from "@mui/material"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { useApiRead } from "../../../../shared/hooks/useApiRead"
import ViewProperty from "../../../../shared/components/ViewProperty"
import ViewArea from "../../../../shared/components/display/ViewArea"
import { BUILDING_ENDPOINT, type IBuilding } from "../../../../shared/models/service/IBuilding"
import ItemViewerTitle from "../../../../shared/components/item_viewer/ItemViewerTitle"
import ViewRichText from "../../../../shared/components/display/ViewRichText"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { BUILDING_EDIT_URL } from "../../config/urls"
import ViewMap from "../../../../shared/components/display/ViewMap"
import { ItemPrefixes } from "../../../../config/config"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import LocationInfo from "../locations/components/LocationInfo"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import MetadataDisplay from "../../../../shared/components/metadata/MetadataDisplay"
import type { IPanariskAppContext } from "../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../app/PanariskApp"
import { useMetadataExists } from "../../../../shared/components/metadata/UseMetadataExists"

const repository = new RestRepository<IBuilding>(BUILDING_ENDPOINT)

/**
 * Render the view page for a locations.
 *
 * @returns {React.ReactElement} element containing the view page.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data } = useApiRead<IBuilding>({ apiFunction: repository.read })
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units

  const metadataExists = useMetadataExists("building")

  return (
    <Container fixed>
      {data !== undefined && (
        <Grid container spacing={2}>
          <PageHeader title="Building" toEdit={`${BUILDING_EDIT_URL}/${data.id}`} />
          <ItemViewerDrawer prefix={ItemPrefixes.location} infoView={LocationInfo} />
          <Grid item xs={12} lg={6}>
            <PaperLocal>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ItemViewerTitle>{data.name}</ItemViewerTitle>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Location">
                    <ViewListItems prefix={ItemPrefixes.location}>{data.location}</ViewListItems>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Occupancy">{data.occupancy?.name}</ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Area">
                    <ViewArea units={units}>{data.area}</ViewArea>
                  </ViewProperty>
                </Grid>
                <Grid item xs={12}>
                  <ViewProperty label="Notes" vertical>
                    <ViewRichText>{data.notes}</ViewRichText>
                  </ViewProperty>
                </Grid>
                {metadataExists && (
                  <Grid item xs={12}>
                    <MetadataDisplay modelName="building" data={data.metadata_data} />
                  </Grid>
                )}
              </Grid>
            </PaperLocal>
          </Grid>

          <Grid item xs={12} lg={6}>
            <PaperLocal>
              <ViewMap place={data} />
            </PaperLocal>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default ViewPage
