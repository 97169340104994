import React from "react"
import { type IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import TableData from "../../../../../shared/components/tables/TableData"
import FiltersData from "../../../../../shared/components/filters/FiltersData"
import { Grid, TableBody, TableHead, TableRow } from "@mui/material"
import { type IInspectionMessage } from "../../../../../shared/models/service/IInspectionMessage"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import { ItemPrefixes } from "../../../../../config/config"
import TableCellCenter from "../../../../../shared/components/tables/TableCellCenter"
import FilterSearch from "../../../../../shared/components/filters/FilterSearch"
import TableCellTruncate from "../../../../../shared/components/tables/TableCellTruncate"
import TableCellData from "../../../../../shared/components/tables/TableCellData"
import TableCellDate from "../../../../../shared/components/tables/TableCellDate"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import InspectionMessageInfo from "./InspectionMessageInfo"
import { useTranslation } from "react-i18next"
import { INSPECTION_MESSAGE_VIEW_URL } from "../../../config/urls"
import TableCellListItems from "../../../../../shared/components/tables/TableCellListItems"

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IInspectionMessage>
}

/**
 * Renders the Inspection Message Index component.
 *
 * @param {IProps} props - The props object.
 * @returns {React.ReactElement} The rendered component.
 */
const InspectionMessageIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults } = props
  const { data } = pagingResults
  const { t } = useTranslation()

  return (
    <Grid container alignItems="center" spacing={2}>
      {pagingResults !== undefined && (
        <>
          <ItemViewerDrawer
            title={t("Message")}
            prefix={ItemPrefixes.inspectionMessage}
            infoView={InspectionMessageInfo}
          />
          <TableData
            toInfo
            pagingResults={pagingResults}
            toInfoPrefix={ItemPrefixes.inspectionMessage}
            to={INSPECTION_MESSAGE_VIEW_URL}
            storageSuffix="inspection/messages">
            <FiltersData pagingResults={pagingResults}>
              <FilterSearch />
            </FiltersData>
            <TableHead>
              <TableCellData field="subject" pagingResults={pagingResults}>
                {t("Subject")}
              </TableCellData>
              <TableCellData field="to_contacts">{t("To")}</TableCellData>
              <TableCellCenter field="created" pagingResults={pagingResults}>
                {t("Created")}
              </TableCellCenter>
            </TableHead>
            <TableBody>
              {data?.results.map((item: IInspectionMessage) => (
                <TableRow key={item.id}>
                  <TableCellTruncate field="subject">{item.subject}</TableCellTruncate>
                  <TableCellListItems field="to_contacts">{item.to_contacts}</TableCellListItems>
                  <TableCellDate field="created">{item.created}</TableCellDate>
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {data?.results.map((item: IInspectionMessage) => (
              <ListDataItem key={item.id} title={item.subject}></ListDataItem>
            ))}
          </ListData>
        </>
      )}
    </Grid>
  )
}

export default InspectionMessageIndex
