import { type IInspection } from "./IInspection"
import { type IMainModel } from "./IMainModel"
import { type IContact } from "./IContact"

export const INSPECTION_MESSAGE_ENDPOINT = "inspection_messages"

export interface IInspectionMessage extends IMainModel {
  id: number
  inspection: IInspection | number
  to_contacts: IContact[]
  subject: string
  content: string
  created: string
  updated: string
}
