import { useCallback, useState } from "react"
import { type RestRepository } from "../repositories/RestRepository"
import { type IMainModel } from "../models/service/IMainModel"
import type { UseFormReturn } from "react-hook-form/dist/types"
import type { IPaging } from "../models/components/IPaging"
import useDebounce from "react-debounced"
import { useAxiosRequest } from "./useAxiosRequest"

export interface IDuplicateCheckProps {
  repository: RestRepository<IMainModel>
  form: UseFormReturn
  fields: string[]
}

interface IDuplicateCheckResponse {
  isDuplicate: boolean
  checkDuplicates: () => void
  duplicateName: string
}

/**
 * A custom hook to perform multi-field duplicate checks in forms using specified fields and a repository.
 *
 * The hook enables checking for duplicate entries based on the values of multiple fields
 * in a form. It uses a debounce mechanism to optimize the duplicate checking process
 * and ensures asynchronous requests are handled efficiently.
 *
 * @param {IDuplicateCheckProps} props - The properties used to configure the duplicate checking process. It includes the repository for querying data, the form instance, and the list of fields to be checked for duplicates.
 * @returns {IDuplicateCheckResponse} - The response of the duplicate checking, containing a boolean indicating if duplicates are found and a function to trigger the duplicate check.
 */
const useMultiFieldDuplicateCheck = (props: IDuplicateCheckProps): IDuplicateCheckResponse => {
  const { repository, form, fields } = props
  const [duplicate, setDuplicate] = useState<boolean>(false)
  const [name, setName] = useState<string>("")

  const debounce = useDebounce()
  const axiosRequest = useAxiosRequest()

  const checkDuplicates = useCallback(() => {
    debounce(async () => {
      await axiosRequest.callRequest(async () => {
        for (const field of fields) {
          if (form.getValues(field) === "") {
            setDuplicate(false)
            return
          }
        }
        const paging: IPaging = {
          filters: fields.map(field => ({
            field,
            value: form.getValues(field),
          })),
        }
        const results = await repository.findAll(paging)
        setDuplicate(results.count > 0)
        if (results.count > 0) {
          const first = results.results[0] as Record<string, any>
          const theName = fields.map(field => `${first[field]}`).join(" ")
          setName(theName)
        }
      })
    })
  }, [fields])

  return {
    isDuplicate: duplicate,
    checkDuplicates,
    duplicateName: name,
  }
}

export default useMultiFieldDuplicateCheck
