import React from "react"
import { TableCell } from "@mui/material"
import { type IListItem } from "../../models/components/IListItem"

interface IProps {
  children: IListItem[] | IListItem | null | undefined
  field?: string
}

/**
 * Renders a TableCell for a list item.
 *
 * @param {IProps} props - The component props.
 * @returns {React.ReactElement} - The rendered TableCell for a list item.
 */
const TableCellListItems: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props
  if (Array.isArray(children)) {
    if (children.length === 0) {
      return <TableCell>Not Set</TableCell>
    }
    if (children.length === 1) {
      return <TableCell>{children[0].name}</TableCell>
    }
    return (
      <TableCell>
        {children[0].name} <sup>+{children.length - 1}</sup>
      </TableCell>
    )
  } else {
    return <TableCell>{children?.name ?? "Not Set"}</TableCell>
  }
}

export default TableCellListItems
