import React, { useCallback, useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import FhMuiTextField from "../forms/FhMuiTextField"
import DialogControls from "../DialogControls"
import { createButton } from "react-simple-wysiwyg"
import LinkIcon from "@mui/icons-material/Link"
import { useTranslation } from "react-i18next"
import { useForm, useFormContext } from "react-hook-form"

interface IProps {
  field: string
}

/**
 * InsertLinkButton component provides functionality to insert or update hyperlink content
 * within a text editor. It manages user input for the link title and URL using a controlled
 * form and interacts with the selected text range to modify or create anchor tags.
 *
 * @type {React.FC<IProps>}
 * @param {IProps} props - Component props containing field and other configurations.
 * @returns {React.ReactElement} A button that triggers a dialog for adding or editing a link.
 */
const InsertLinkButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { field } = props
  const { t } = useTranslation()

  const formContext = useFormContext()

  const form = useForm()
  const [open, setOpen] = useState(false)
  const [range, setRange] = useState<Range | undefined>()

  const handleClose = useCallback(async () => {
    setOpen(false)
    form.reset()
  }, [])

  const handleSave = useCallback(async () => {
    const linkTitle = form.getValues("title")
    const linkUrl = form.getValues("link")
    if (range !== undefined) {
      // check to see if the range is a link. If it is just a link then update it.
      const parentNode = range.commonAncestorContainer.parentNode as HTMLAnchorElement
      if (parentNode.tagName === "A") {
        parentNode.href = linkUrl
        parentNode.innerHTML = linkTitle
      } else {
        // change the selected text to an anchor tag with the href = linkUrl and content = linkTitle
        range.deleteContents()
        const anchor = document.createElement("a")
        anchor.href = linkUrl
        anchor.innerHTML = linkTitle
        range.insertNode(anchor)
      }
      const editorNode = document.querySelector(".rsw-ce")
      const editorElement = editorNode as HTMLElement
      formContext.setValue(field, editorElement.innerHTML)
    }
    setOpen(false)
  }, [range, form])

  const handleOpen = useCallback(() => {
    const selection = window.getSelection()
    setRange(selection?.getRangeAt(0))
    if (selection !== null && selection.rangeCount > 0) {
      // Get the parent node of the selection and check if it is an A tag, if it is then get the href
      const parentNode = selection.getRangeAt(0).commonAncestorContainer.parentNode as HTMLElement
      form.setValue("title", selection.toString())
      // Get the attribute href from parentNode.
      form.setValue("link", parentNode.getAttribute("href"))
      setOpen(true)
    }
  }, [])

  const BtnInsertLink = createButton(t("Insert Link"), <LinkIcon fontSize="small" />, handleOpen)

  return (
    <>
    {formContext !== null && <BtnInsertLink />}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t("Insert Link")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} defaultValue={""} name="title" />
            </Grid>
            <Grid item xs={12}>
              <FhMuiTextField control={form.control} defaultValue={""} name="link" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleSave} onCancel={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InsertLinkButton
