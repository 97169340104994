import { type ISectionDataImage, type ISectionDataRichText, type ISectionDataTable, type SectionType } from "./IRiskWriterSectionData"

export interface IRiskWriterSection {
  name: string
  required: boolean
  title: string
  default_value: ISectionDataRichText | ISectionDataImage | ISectionDataTable | null
  helper_text: string | null
  cols: number | null
  allowed_content_types: SectionType[] | null
  sections: IRiskWriterSection[] | null
}


/**
 * Finds a section by name within an array of sections.
 *
 * @param {IRiskWriterSection[] | null | undefined} sections - The array of sections to search within.
 * @param {string} sectionName - The name of the section to find.
 * @returns {IRiskWriterSection | null} - The found section, or null if not found.
 */
export const findSectionByName = (sections: IRiskWriterSection[] | null | undefined, sectionName: string): IRiskWriterSection | null => {
  if (sections === null || sections === undefined) {
    return null
  }
  for (const section of sections) {
    if (section.name === sectionName) {
      return section
    }
    const found = findSectionByName(section.sections, sectionName)
    if (found !== null) {
      return found
    }
  }

  return null
}