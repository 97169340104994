import React, { useCallback, useState } from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Alert, Grid } from "@mui/material"
import FhMuiHiddenField from "../../../../../shared/components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import { CONTACT_ENDPOINT, type IContact } from "../../../../../shared/models/service/IContact"
import { requiredRule } from "../../../../../shared/utilities/form_utility"
import useLoadFormData from "../../../../../shared/hooks/useLoadFormData"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import PaperLocal from "../../../../../shared/components/containers/PaperLocal"
import FhMuiPhoneField from "../../../../../shared/components/forms/FhMuiPhoneField"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../../shared/models/service/IServiceUser"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import SelectFilteredSingle, {
  useSelectFilteredSingle,
} from "../../../../../shared/components/forms/SelectFilteredSingle"
import {
  type ILocation,
  LOCATION_CITY_ENDPOINT,
  LOCATION_COUNTRY_ENDPOINT,
  LOCATION_STATE_REGION_ENDPOINT,
} from "../../../../../shared/models/service/ILocation"
import AddressSearch from "../../locations/components/AddressSearch"
import FhMap from "../../../../../shared/components/forms/FhMap"
import { useTranslation } from "react-i18next"
import useMultiFieldDuplicateCheck, {
  type IDuplicateCheckProps,
} from "../../../../../shared/hooks/useMultiFieldDuplicateCheck"
import { type IMainModel } from "../../../../../shared/models/service/IMainModel"

const serviceUsersRepository = new RestRepository<IServiceUser | IListItem>(SERVICE_USER_ENDPOINT)
const contactsRepository = new RestRepository<IMainModel>(CONTACT_ENDPOINT)

const cityRepository = new RestRepository<IListItem>(LOCATION_CITY_ENDPOINT)
const stateRepository = new RestRepository<IListItem>(LOCATION_STATE_REGION_ENDPOINT)
const countryRepository = new RestRepository<IListItem>(LOCATION_COUNTRY_ENDPOINT)

interface IProps {
  form: UseFormReturn
  contact?: IContact | undefined | null
  isEdit?: boolean
}

/**
 * Use this forms to add or edit a contact.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const ContactForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { contact, form, isEdit = false } = props

  const { t } = useTranslation()
  const duplicateCheckProps: IDuplicateCheckProps = {
    repository: contactsRepository,
    form,
    fields: ["first_name", "last_name"],
  }
  const duplicateCheck = useMultiFieldDuplicateCheck(duplicateCheckProps)
  const { isDuplicate, checkDuplicates, duplicateName } = duplicateCheck

  const [serviceUser, setServiceUser] = useSelectFilteredSingle("service_user", form)

  const [addressSearch, setAddressSearch] = useState<string>("")
  const [redraw, setRedraw] = useState<boolean>(false)
  const [city, setCity] = useSelectFilteredSingle("city", form)
  const [stateRegion, setStateRegion] = useSelectFilteredSingle("state_region", form)
  const [country, setCountry] = useSelectFilteredSingle("country", form)

  useLoadFormData<IContact>(
    (data: IContact) => {
      form.setValue("id", data.id)
      form.setValue("first_name", data.first_name)
      form.setValue("last_name", data.last_name)
      form.setValue("business_name", data.business_name)
      form.setValue("job_title", data.job_title)
      form.setValue("email", data.email)
      form.setValue("phone", data.phone)
      form.setValue("phone_ext", data.phone_ext)
      form.setValue("email_2", data.email_2)
      form.setValue("phone_2", data.phone_2)
      form.setValue("phone_2_ext", data.phone_2_ext)

      form.setValue("address", data.address ?? "")
      form.setValue("address_2", data.address_2 ?? "")
      form.setValue("postal_code", data.postal_code ?? "")
      form.setValue("longitude", data.longitude ?? "")
      form.setValue("latitude", data.latitude ?? "")
      form.setValue("zoom", data.zoom ?? 17)

      setCity(data.city !== null ? { id: data.city, name: data.city } : null)
      setStateRegion(data.state_region !== null ? { id: data.state_region, name: data.state_region } : null)
      setCountry(data.country !== null ? { id: data.country, name: data.country } : null)
      setAddressSearch(data.address)
      setRedraw(redraw => !redraw)

      form.setValue("notes", data.notes)

      setServiceUser(data.service_user)
    },
    contact,
    isEdit,
    form.setValue,
  )

  const handleAddressSearch = useCallback(() => {
    setAddressSearch(form.getValues("address") as string)
  }, [form])

  const handleFoundAddress = useCallback(
    (data: ILocation) => {
      form.setValue("address", data.address)
      form.setValue("address_2", data.address_2)
      form.setValue("postal_code", data.postal_code)
      form.setValue("longitude", data.longitude)
      form.setValue("latitude", data.latitude)
      form.setValue("metadata_data", data.metadata_data)
      setCity({ id: data.city, name: data.city })
      setStateRegion({ id: data.state_region, name: data.state_region })
      setCountry({ id: data.country, name: data.country })
      setRedraw(redraw => !redraw)
    },
    [form, redraw],
  )

  const handleRedraw = useCallback(async () => {
    setRedraw(redraw => !redraw)
  }, [redraw])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <PaperLocal>
            {isEdit && <FhMuiHiddenField control={form.control} />}
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FhMuiTextField
                  control={form.control}
                  name="first_name"
                  rules={requiredRule()}
                  onChange={checkDuplicates}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FhMuiTextField control={form.control} name="last_name" onChange={checkDuplicates} />
              </Grid>
              {isDuplicate && (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    {t("This contact might already exist.")} {duplicateName}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <FhMuiTextField control={form.control} name="job_title" />
              </Grid>
              <Grid item xs={12}>
                <FhMuiTextField control={form.control} name="business_name" />
              </Grid>
              <Grid item xs={12} lg>
                <FhMuiPhoneField control={form.control} name="phone" />
              </Grid>
              <Grid item>
                <FhMuiTextField control={form.control} name="phone_ext" />
              </Grid>
              <Grid item xs={12}>
                <FhMuiTextField control={form.control} name="email" />
              </Grid>
              <Grid item xs={12} lg>
                <FhMuiPhoneField control={form.control} name="phone_2" />
              </Grid>
              <Grid item>
                <FhMuiTextField control={form.control} name="phone_2_ext" />
              </Grid>
              <Grid item xs={12}>
                <FhMuiTextField control={form.control} name="email_2" />
              </Grid>
            </Grid>
          </PaperLocal>
          <PaperLocal sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FhMuiRichTextField control={form.control} name="notes" />
              </Grid>
            </Grid>
          </PaperLocal>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PaperLocal>
                <SelectFilteredSingle
                  name="service_user"
                  defaultValue={serviceUser}
                  repository={serviceUsersRepository}
                  onChange={setServiceUser}
                />
                <Alert severity="info" sx={{ mt: 2 }}>
                  Link this contact to a service user. All changes made the service user will update the contact.
                </Alert>
              </PaperLocal>
            </Grid>
            <Grid item xs={12}>
              <PaperLocal>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <FhMuiTextField control={form.control} name="address" onChange={handleAddressSearch} />
                  </Grid>
                  <Grid item>
                    <AddressSearch address={addressSearch} onFound={handleFoundAddress} />
                  </Grid>
                  <Grid item xs={12}>
                    <FhMuiTextField control={form.control} name="address_2" />
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <SelectFilteredSingle
                      freeSolo
                      showId={false}
                      name="city"
                      defaultValue={city}
                      repository={cityRepository}
                      onChange={setCity}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FhMuiTextField control={form.control} name="postal_code" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <SelectFilteredSingle
                      freeSolo
                      showId={false}
                      name="state_region"
                      defaultValue={stateRegion}
                      repository={stateRepository}
                      onChange={setStateRegion}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <SelectFilteredSingle
                      freeSolo
                      showId={false}
                      name="country"
                      defaultValue={country}
                      repository={countryRepository}
                      onChange={setCountry}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FhMuiTextField control={form.control} name="zoom" />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FhMuiTextField control={form.control} name="latitude" onChange={handleRedraw} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FhMuiTextField control={form.control} name="longitude" onChange={handleRedraw} />
                  </Grid>
                  <Grid item xs={12}>
                    <FhMap form={form} redraw={redraw} />
                  </Grid>
                </Grid>
              </PaperLocal>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ContactForm
