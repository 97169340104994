import React from "react"
import { TableBody, TableHead, TableRow } from "@mui/material"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../../../shared/models/service/IInspection"
import PageHeader from "../../../../../shared/components/pages/PageHeader"
import TableData from "../../../../../shared/components/tables/TableData"
import TableCellData from "../../../../../shared/components/tables/TableCellData"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import ListProperty from "../../../../../shared/components/lists/ListProperty"
import FiltersData from "../../../../../shared/components/filters/FiltersData"
import FilterLocation from "../../../../../shared/components/filters/FilterLocation"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import FilterAccount from "../../../../../shared/components/filters/FilterAccount"
import TableCellCenter from "../../../../../shared/components/tables/TableCellCenter"
import TableCellDate from "../../../../../shared/components/tables/TableCellDate"
import { ItemPrefixes, PriorityTypes } from "../../../../../config/config"
import ItemViewerDrawer from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import InspectionInfo from "./InspectionInfo"
import FilterReportFormat from "../../../../../shared/components/filters/FilterReportFormat"
import type { IFilter } from "../../../../../shared/models/components/IFilter"
import FilterPriority from "../../../../../shared/components/filters/FilterPriority"
import FilterDate from "../../../../../shared/components/filters/FilterDate"
import FilterSearch from "../../../../../shared/components/filters/FilterSearch"
import FilterInspectionType from "../../../../../shared/components/filters/FilterInspectionType"
import TableCellTruncate from "../../../../../shared/components/tables/TableCellTruncate"
import TableCellLocation from "../../../../../shared/components/tables/TableCellLocation"
import { useTranslation } from "react-i18next"
import { INSPECTION_VIEW_URL } from "../../../config/urls"
import FilterInspectionStatus from "../../../../../shared/components/filters/FilterInspectionStatus"

const gotoRepo = new RestRepository<IListItem>(INSPECTION_ENDPOINT)

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<IInspection>
  showHeader?: boolean
  useInfoView?: boolean
  gotoFilters?: IFilter[]
}

/**
 * Renders the Inspection Index.
 *
 * @param {IProps} props - The properties for the Inspection Index.
 * @returns {React.ReactElement} The rendered Inspection Index.
 */
const InspectionIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, showHeader = true, useInfoView = false, gotoFilters } = props
  const { data } = pagingResults
  const { t } = useTranslation()

  return (
    <>
      {showHeader && <PageHeader title="Inspection" titlePlural="Inspections" />}
      {pagingResults !== undefined && (
        <>
          <ItemViewerDrawer title={t("Inspection")} prefix={ItemPrefixes.inspection} infoView={InspectionInfo} />
          <TableData
            pagingResults={pagingResults}
            to={INSPECTION_VIEW_URL}
            repository={gotoRepo}
            storageSuffix="inspections"
            toInfoPrefix={ItemPrefixes.inspection}
            gotoFilters={gotoFilters}
            toInfo={useInfoView}>
            <FiltersData pagingResults={pagingResults}>
              <FilterSearch />
              <FilterAccount field="account" />
              <FilterLocation field="location" />
              <FilterReportFormat field="report_format" />
              <FilterInspectionType />
              <FilterInspectionStatus />
              <FilterPriority field="priority" priorityType={PriorityTypes.INSPECTION} />
              <FilterDate field="vendor_due_date" title="Vendor Due Date" />
              <FilterDate field="vendor_site_visit_date" title="Vendor Site Visit Date" />
              <FilterDate field="vendor_submitted_date" title="Vendor Submitted Date" />
              <FilterDate field="vendor_returned_date" title="Vendor Returned Date" />
            </FiltersData>
            <TableHead>
              <TableCellData field="identifier">Identifier</TableCellData>
              <TableCellData field="account" pagingResults={pagingResults}>Account</TableCellData>
              <TableCellData field="location" pagingResults={pagingResults}>Location</TableCellData>
              <TableCellData field="inspection_type" pagingResults={pagingResults}>Type</TableCellData>
              <TableCellData field="report_format" pagingResults={pagingResults}>Report Format</TableCellData>
              <TableCellCenter field="priority" pagingResults={pagingResults}>Priority</TableCellCenter>
              <TableCellCenter field="inspection_status" pagingResults={pagingResults}>Status</TableCellCenter>
              <TableCellCenter field="vendor" pagingResults={pagingResults}>Vendor</TableCellCenter>
              <TableCellCenter field="vendor_member" pagingResults={pagingResults}>Vendor Member</TableCellCenter>
              <TableCellCenter field="vendor_due_date">Vendor Due Date</TableCellCenter>
              <TableCellCenter field="vendor_site_visit_date">Vendor Site Visit Date</TableCellCenter>
              <TableCellCenter field="vendor_submitted_date">Vendor Submitted Date</TableCellCenter>
              <TableCellCenter field="vendor_returned_date">Vendor Returned Date</TableCellCenter>
            </TableHead>
            <TableBody>
              {data?.results.map((item: IInspection) => (
                <TableRow key={item.id}>
                  <TableCellData field="identifier">{item.identifier}</TableCellData>
                  <TableCellData field="account">{item.account?.name}</TableCellData>
                  <TableCellLocation field="location">{item.location}</TableCellLocation>
                  <TableCellData field="inspection_type">{item.inspection_type?.name}</TableCellData>
                  <TableCellTruncate field="report_format">{item.report_format?.name}</TableCellTruncate>
                  <TableCellData field="priority">{item.priority?.name}</TableCellData>
                  <TableCellData field="inspection_status">{item.inspection_status?.name}</TableCellData>
                  <TableCellData field="vendor">{item.vendor?.name}</TableCellData>
                  <TableCellData field="vendor_member">{item.vendor_member?.name}</TableCellData>
                  <TableCellDate field="vendor_due_date">{item.vendor_due_date}</TableCellDate>
                  <TableCellDate field="vendor_site_visit_date">{item.vendor_site_visit_date}</TableCellDate>
                  <TableCellDate field="vendor_submitted_date">{item.vendor_submitted_date}</TableCellDate>
                  <TableCellDate field="vendor_returned_date">{item.vendor_returned_date}</TableCellDate>
                </TableRow>
              ))}
            </TableBody>
          </TableData>

          <ListData pagingResults={pagingResults}>
            {data?.results.map((item: IInspection) => (
              <ListDataItem key={item.id} to={`${INSPECTION_VIEW_URL}/${item.id}`} title={item.location.name}>
                <ListProperty label="Account">{item.account?.name}</ListProperty>
              </ListDataItem>
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default InspectionIndex
