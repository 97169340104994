import React, { useContext } from "react"
import { type ILocation, LOCATION_ENDPOINT } from "../../../../../shared/models/service/ILocation"
import { TableBody, TableHead, TableRow } from "@mui/material"
import { LOCATION_ADD_URL, LOCATION_VIEW_URL } from "../../../config/urls"
import { type IUseApiPagedResultsResponse } from "../../../../../shared/hooks/useApiPagedLocal"
import TableData from "../../../../../shared/components/tables/TableData"
import PageHeader from "../../../../../shared/components/pages/PageHeader"
import TableCellArea from "../../../../../shared/components/tables/TableCellArea"
import TableCellCenter from "../../../../../shared/components/tables/TableCellCenter"
import TableCellData from "../../../../../shared/components/tables/TableCellData"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IListItem } from "../../../../../shared/models/components/IListItem"
import ListData from "../../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../../shared/components/lists/ListDataItem"
import ListProperty from "../../../../../shared/components/lists/ListProperty"
import TableCellDate from "../../../../../shared/components/tables/TableCellDate"
import { ItemPrefixes } from "../../../../../config/config"
import type { IFilter } from "../../../../../shared/models/components/IFilter"
import TableCellTruncate from "../../../../../shared/components/tables/TableCellTruncate"
import FiltersData from "../../../../../shared/components/filters/FiltersData"
import FilterAccount from "../../../../../shared/components/filters/FilterAccount"
import FilterLocationCity from "../../../../../shared/components/filters/FilterLocationCity"
import FilterSearch from "../../../../../shared/components/filters/FilterSearch"
import FilterLocationStateRegion from "../../../../../shared/components/filters/FilterLocationStateRegion"
import FilterLocationCountry from "../../../../../shared/components/filters/FilterLocationCountry"
import TableActions from "../../../../../shared/components/tables/TableActions"
import ExcelDownloadButton from "../../../../../shared/components/ExcelDownloadButton"
import TableCellListItems from "../../../../../shared/components/tables/TableCellListItems"
import type { IPanariskAppContext } from "../../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../../app/PanariskApp"
import {
  useMetadataTableFilter,
  useMetadataTableHeader,
  useMetadataTableRow,
} from "../../../../../shared/components/metadata/UseMetadataTable"

interface IProps {
  pagingResults: IUseApiPagedResultsResponse<ILocation> | undefined
  showHeader?: boolean
  useInfoView?: boolean
  gotoFilters?: IFilter[]
}

const repository = new RestRepository<IListItem>(LOCATION_ENDPOINT)

const modelName = "location"

/**
 * Displays the information for locations in a table.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the locations information table.
 */
const LocationIndex: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { pagingResults, showHeader = true, useInfoView = false, gotoFilters } = props
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units

  const metadataTableHeader = useMetadataTableHeader(modelName, pagingResults)
  const metadataTableRow = useMetadataTableRow(modelName)
  const metadataTableFilters = useMetadataTableFilter(modelName)

  return (
    <>
      {showHeader && <PageHeader title="Location" titlePlural="Locations" toAdd={LOCATION_ADD_URL} />}
      {pagingResults !== undefined && (
        <>
          <TableData
            pagingResults={pagingResults}
            to={LOCATION_VIEW_URL}
            toInfo={useInfoView}
            toInfoPrefix={ItemPrefixes.location}
            repository={repository}
            gotoFilters={gotoFilters}
            storageSuffix="locations">
            <TableActions>
              <ExcelDownloadButton repository={repository} paging={pagingResults.paging} />
            </TableActions>
            <FiltersData pagingResults={pagingResults} modelName="location">
              <FilterSearch />
              <FilterAccount field="accounts" />
              <FilterLocationCity field="city" />
              <FilterLocationStateRegion field="state_region" />
              <FilterLocationCountry field="country" />
              {metadataTableFilters()}
            </FiltersData>
            <TableHead>
              <TableCellCenter field="identifier">Identifier</TableCellCenter>
              <TableCellCenter field="name" pagingResults={pagingResults}>
                Location
              </TableCellCenter>
              <TableCellCenter field="accounts" pagingResults={pagingResults}>
                Account
              </TableCellCenter>
              <TableCellCenter field="address">Address</TableCellCenter>
              <TableCellCenter field="city" pagingResults={pagingResults}>
                City
              </TableCellCenter>
              <TableCellCenter field="state_region" pagingResults={pagingResults}>
                State/Region
              </TableCellCenter>
              <TableCellCenter field="country" pagingResults={pagingResults}>
                Country
              </TableCellCenter>
              <TableCellCenter field="area" pagingResults={pagingResults} wrap={false}>
                {`Area ${units === "US" ? "ft<sup>2</sup>" : "m<sup>2</sup>"}`}
              </TableCellCenter>
              <TableCellCenter field="primary_staff" pagingResults={pagingResults}>
                Primary Staff
              </TableCellCenter>
              {metadataTableHeader()}
              <TableCellCenter field="created" pagingResults={pagingResults}>
                Created
              </TableCellCenter>
            </TableHead>
            <TableBody>
              {pagingResults.data?.results.map((item: ILocation) => (
                <TableRow key={item.id}>
                  <TableCellCenter field="identifier">{item.identifier}</TableCellCenter>
                  <TableCellData field="name">{item.name}</TableCellData>
                  <TableCellTruncate field="accounts">
                    {item.accounts.map(account => account.name).toString()}
                  </TableCellTruncate>
                  <TableCellListItems field="primary_staff">{item.primary_staff}</TableCellListItems>
                  <TableCellData field="address">{item.address}</TableCellData>
                  <TableCellData field="city">{item.city}</TableCellData>
                  <TableCellData field="state_region">{item.state_region}</TableCellData>
                  <TableCellData field="country">{item.country}</TableCellData>
                  <TableCellArea field="area" units={units}>
                    {item.area}
                  </TableCellArea>
                  {metadataTableRow(item.metadata_data)}
                  <TableCellDate field="created">{item.created}</TableCellDate>
                </TableRow>
              ))}
            </TableBody>
          </TableData>
          <ListData pagingResults={pagingResults}>
            {pagingResults.data?.results.map((item: ILocation) => (
              <ListDataItem key={item.id} to={`${LOCATION_VIEW_URL}/${item.id}`} title={item.name}>
                <ListProperty label="City">{item.city}</ListProperty>
                <ListProperty label="State">{item.state_region}</ListProperty>
              </ListDataItem>
            ))}
          </ListData>
        </>
      )}
    </>
  )
}

export default LocationIndex
