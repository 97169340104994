import React from "react"
import { type UseFormReturn } from "react-hook-form/dist/types"
import { Grid } from "@mui/material"
import FhMuiHiddenField from "../../../components/forms/FhMuiHiddenField"
import FhMuiTextField from "../../../components/forms/FhMuiTextField"
import FhMuiRichTextField from "../../../components/forms/FhMuiRichTextField"
import { type ILocationImpairment } from "../../../models/service/ILocationImpairment"
import { requiredRule } from "../../../utilities/form_utility"
import useLoadFormData from "../../../hooks/useLoadFormData"
import { RestRepository } from "../../../repositories/RestRepository"
import { type ILocation, LOCATION_ENDPOINT } from "../../../models/service/ILocation"
import { type IListItem } from "../../../models/components/IListItem"
import SelectFilteredSingle, { useSelectFilteredSingle } from "../../../components/forms/SelectFilteredSingle"
import FhMuiDateField from "../../../components/forms/FhMuiDateField"
import PaperLocal from "../../../components/containers/PaperLocal"
import SelectFilteredMultiple, { useSelectFilteredMultiple } from "../../../components/forms/SelectFilteredMultiple"
import {
  type ILocationImpairmentTag,
  LOCATION_IMPAIRMENT_TAG_ENDPOINT,
} from "../../../models/service/ILocationImpairmentTag"
import {
  type ILocationImpairmentSystemType,
  LOCATION_IMPAIRMENT_SYSTEM_TYPE_ENDPOINT,
} from "../../../models/service/ILocationImpairmentSystemType"
import {
  type ILocationImpairmentStatus,
  LOCATION_IMPAIRMENT_STATUS_ENDPOINT,
} from "../../../models/service/ILocationImpairmentStatus"
import {
  type ILocationImpairmentType,
  LOCATION_IMPAIRMENT_TYPE_ENDPOINT,
} from "../../../models/service/ILocationImpairmentType"
import { useMetadataExists } from "../../../components/metadata/UseMetadataExists"
import MetadataForm from "../../../components/metadata/MetadataForm"

interface IProps {
  form: UseFormReturn
  locationImpairment?: ILocationImpairment | undefined | null
  isEdit?: boolean
}

const locationRepository = new RestRepository<ILocation | IListItem>(LOCATION_ENDPOINT)
const tagRepository = new RestRepository<ILocationImpairmentTag | IListItem>(LOCATION_IMPAIRMENT_TAG_ENDPOINT)
const systemTypeRepository = new RestRepository<ILocationImpairmentSystemType | IListItem>(
  LOCATION_IMPAIRMENT_SYSTEM_TYPE_ENDPOINT,
)
const statusRepository = new RestRepository<ILocationImpairmentStatus | IListItem>(LOCATION_IMPAIRMENT_STATUS_ENDPOINT)
const typeRepository = new RestRepository<ILocationImpairmentType | IListItem>(LOCATION_IMPAIRMENT_TYPE_ENDPOINT)

/**
 * Use this forms to add or edit a location impairment.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} returns the forms.
 */
const LocationImpairmentForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { locationImpairment, form, isEdit = false } = props

  const [location, setLocation] = useSelectFilteredSingle("location", form)
  const [systemType, setSystemType] = useSelectFilteredSingle("system_type", form)
  const [status, setStatus] = useSelectFilteredSingle("status", form)
  const [type, setType] = useSelectFilteredSingle("impairment_type", form)
  const [tags, setTags] = useSelectFilteredMultiple("tags", form)

  const metadataExists = useMetadataExists("location_impairment")

  useLoadFormData<ILocationImpairment>(
    (data: ILocationImpairment) => {
      form.setValue("id", data.id)
      form.setValue("title", data.title)
      form.setValue("description", data.description)
      form.setValue("system_name", data.system_name)
      form.setValue("start_date", data.start_date)
      form.setValue("end_date", data.end_date)
      form.setValue("metadata_data", data.metadata_data)

      setLocation(data.location)
      setType(data.impairment_type)
      setStatus(data.status)
      setSystemType(data.system_type)
      setTags(data.tags)
    },
    locationImpairment,
    isEdit,
    form.setValue,
  )

  return (
    <>
      {isEdit && <FhMuiHiddenField control={form.control} />}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <PaperLocal>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FhMuiTextField control={form.control} name="title" rules={requiredRule()} />
              </Grid>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="location"
                  label="Location"
                  defaultValue={location}
                  repository={locationRepository}
                  onChange={setLocation}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FhMuiDateField control={form.control} name="start_date" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FhMuiDateField control={form.control} name="end_date" />
              </Grid>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="impairment_type"
                  label="Type"
                  defaultValue={type}
                  repository={typeRepository}
                  onChange={setType}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="status"
                  label="Status"
                  defaultValue={status}
                  repository={statusRepository}
                  onChange={setStatus}
                />
              </Grid>
              <Grid item xs={12}>
                <FhMuiTextField control={form.control} name="system_name" />
              </Grid>
              <Grid item xs={12}>
                <SelectFilteredSingle
                  name="system_type"
                  label="System Type"
                  defaultValue={systemType}
                  repository={systemTypeRepository}
                  onChange={setSystemType}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectFilteredMultiple name="tags" defaultValue={tags} repository={tagRepository} onChange={setTags} />
              </Grid>
              {metadataExists && (
                <Grid item xs={12}>
                  <MetadataForm modelName="location_impairment" form={form} />
                </Grid>
              )}
            </Grid>
          </PaperLocal>
        </Grid>
        <Grid item xs={12} lg={6}>
          <PaperLocal>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FhMuiRichTextField control={form.control} name="description" />
              </Grid>
            </Grid>
          </PaperLocal>
        </Grid>
      </Grid>
    </>
  )
}

export default LocationImpairmentForm
