import React, { useCallback, useContext } from "react"
import { Container, Grid } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import RestApiKeyForm from "./components/RestApiKeyForm"
import { type IRestApiKey, REST_API_KEY_ENDPOINT } from "../../../../shared/models/service/IRestApiKey"
import { REST_API_KEY_VIEW_URL } from "../../config/urls"
import useApiAdd, { type IUseApiAddProps } from "../../../../shared/hooks/useApiAdd"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"
import { useNavigate } from "react-router-dom"
import { PanariskAppContext } from "../../../../app/PanariskApp"

const repository = new RestRepository<IRestApiKey>(REST_API_KEY_ENDPOINT)
const redirectView = REST_API_KEY_VIEW_URL

/**
 * This page will allow adding of a rest api key.
 *
 * @returns {React.FC} the rest api key add page.
 */
const AddPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const navigate = useNavigate()
  const panariskAppContext = useContext(PanariskAppContext)

  const props: IUseApiAddProps<IRestApiKey> = { apiFunction: repository.add, setError: form.setError, redirectView }
  const apiAdd = useApiAdd<IRestApiKey>(props)

  const handleSubmit = useCallback(async (apiKey: IRestApiKey) => {
    const returnedApiKey = await apiAdd.handleAdd(apiKey, true)
    if (returnedApiKey?.id !== undefined) {
      panariskAppContext.setRestApiKey?.(returnedApiKey.api_key)
      navigate(`${redirectView}/${returnedApiKey.id}`)
    }
  }, [])

  return (
    <Container fixed>
      <FormProvider {...form}>
        <Grid container spacing={2}>
          <FormHeader title="Add API Key" loading={apiAdd.saving} errors={form.formState.errors} />
          <FormErrors connectionError={apiAdd.connectionError} />
          <FormBox form={form} onSubmit={handleSubmit}>
            <RestApiKeyForm form={form} />
          </FormBox>
        </Grid>
      </FormProvider>
    </Container>
  )
}

export default AddPage
