import React from "react"
import { Grid } from "@mui/material"

import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { type IVendor, VENDOR_ENDPOINT } from "../../../../../shared/models/service/IVendor"
import { VENDOR_VIEW_URL } from "../../../config/urls"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import FormatDate from "../../../../../shared/components/format/FormatDate"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ViewRichText from "../../../../../shared/components/display/ViewRichText"
import ViewListItems from "../../../../../shared/components/display/ViewListItems"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IVendor>(VENDOR_ENDPOINT)

/**
 * Renders a page to view vendor details.
 *
 * @param {IInfoViewProps} props See props for details
 * @returns {React.ReactElement} The rendered component.
 */
const VendorInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const { data } = useApiRead<IVendor>({ apiFunction: repository.read, itemId: infoId })
  const { t } = useTranslation()

  return (
    <>
      {data !== undefined && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ItemViewerTitle to={`${VENDOR_VIEW_URL}/${infoId}`}>{data.name}</ItemViewerTitle>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Lead")}>
                <ViewListItems>{data.lead}</ViewListItems>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Assistants")}>
                <ViewListItems>{data.assistants}</ViewListItems>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Additional Contacts")}>
                <ViewListItems>{data.contacts}</ViewListItems>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Notes")} vertical>
                <ViewRichText>{data.notes}</ViewRichText>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Created")}>
                <FormatDate value={data.created} />
              </ViewProperty>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default VendorInfo
