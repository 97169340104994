import React, { useCallback, useState } from "react"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import FhMuiDateField from "../forms/FhMuiDateField"
import FormatDate from "../format/FormatDate"
import DialogControls from "../DialogControls"
import { type RestRepository } from "../../repositories/RestRepository"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import moment from "moment"

interface IProps {
  field: string
  value: string | null
  parentId: number
  repository: RestRepository<any>
  onChange?: () => Promise<void>
}

/**
 * Renders a dialog for changing a date value. Includes a button to open the dialog, a form to edit the date value, and
 * controls to save or cancel changes.
 *
 * @param {IProps} props - Props containing the field name, current value, parent identifier, and repository for data manipulation.
 *
 * @returns {React.ReactElement} A React element containing a button and the date change dialog.
 */
const DateChangeDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { field, value, parentId, repository, onChange } = props
  const [open, setOpen] = useState(false)
  const form = useForm()
  const { t } = useTranslation()
  const axiosRequest = useAxiosRequest()

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    form.setValue(field, value ?? moment().format("YYYY-MM-DD"))
    setOpen(true)
  }, [value])

  const handleSave = useCallback(async () => {
    await axiosRequest.callRequest(async () => {
      await repository.patch({[field]: form.getValues(field)}, parentId)
      handleClose()
      await onChange?.()
    })
  }, [field, form, parentId])

  return (
    <>
      <Button onClick={handleOpen}>
        <FormatDate value={value} />
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>{t("Change Date")}</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <FormProvider {...form}>
              <FhMuiDateField control={form.control} name={field} />
            </FormProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogControls loading={axiosRequest.loading} onSave={handleSave} onCancel={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DateChangeDialog
