import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { type ILocation, LOCATION_ENDPOINT } from "../../../../shared/models/service/ILocation"
import { DEFAULT_CENTER, DEFAULT_GOOGLE_MAP_ID, ItemPrefixes, PriorityTypes } from "../../../../config/config"
import { Box, Button, ButtonGroup, Checkbox, ClickAwayListener, Grid, List, ListItem, Popper } from "@mui/material"
import { InfoWindow, Map, Marker, useMap } from "@vis.gl/react-google-maps"
import { Link } from "react-router-dom"
import FiltersData from "../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../shared/components/filters/FilterSearch"
import FilterAccount from "../../../../shared/components/filters/FilterAccount"
import FilterLocationCity from "../../../../shared/components/filters/FilterLocationCity"
import FilterLocationStateRegion from "../../../../shared/components/filters/FilterLocationStateRegion"
import FilterLocationCountry from "../../../../shared/components/filters/FilterLocationCountry"
import { type IUseApiPagedResultsProps, useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import ItemViewerDrawer, { useItemEditDialogUrl } from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import LocationInfo from "../locations/components/LocationInfo"
import { useTranslation } from "react-i18next"
import useAuth, { type IProfilePatch } from "../../../../shared/hooks/useAuth"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import CloudIcon from "@mui/icons-material/Cloud"
import PaperLocal from "../../../../shared/components/containers/PaperLocal"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import useLocalStorage from "beautiful-react-hooks/useLocalStorage"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../../shared/models/service/IInspection"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../../../shared/models/service/IServiceUser"
import ServiceUserInfo from "../service_users/components/ServiceUserInfo"
import ViewListItems from "../../../../shared/components/display/ViewListItems"
import InspectionInfo from "../inspections/components/InspectionInfo"
import FilterLocation from "../../../../shared/components/filters/FilterLocation"
import FilterReportFormat from "../../../../shared/components/filters/FilterReportFormat"
import FilterInspectionType from "../../../../shared/components/filters/FilterInspectionType"
import FilterPriority from "../../../../shared/components/filters/FilterPriority"
import FilterDate from "../../../../shared/components/filters/FilterDate"
import FilterVendor from "../../../../shared/components/filters/FilterVendor"
import { BASE_URL } from "../../../../shared/utilities/request_utility"
import type { IPanariskAppContext } from "../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../app/PanariskApp"

const height = "calc(100vh - 100px)"
const zoom = 4

/**
 * Calculates adjusted latitude and longitude values based on the input location and index.
 *
 * @param {ILocation} location An object containing the latitude and longitude properties.
 * @param {number} index A numerical value used to adjust the coordinates.
 * @returns {Record<string, number>} An object with `lat` and `lng` properties representing the adjusted coordinates.
 */
const latLong = (location: ILocation | IServiceUser, index: number): google.maps.LatLngLiteral => {
  return {
    lat: Number(location.latitude) + Math.sin(index) / 10000,
    lng: Number(location.longitude) + Math.sin(index) / 10000,
  }
}

const locationRepository = new RestRepository<ILocation>(LOCATION_ENDPOINT)
const inspectionRepository = new RestRepository<IInspection>(INSPECTION_ENDPOINT)
const userRepository = new RestRepository<IServiceUser>(SERVICE_USER_ENDPOINT)

const defaultLimit = 100

/**
 * Displays a map of the locations.
 *
 * @returns {React.FC} the locations map.
 */
const LocationsMap: React.FC = (): React.ReactElement => {
  const propsLocation: IUseApiPagedResultsProps<ILocation> = {
    apiFunction: locationRepository.findAll,
    defaultLimit,
    prefix: "LOCATION_P",
  }
  const pagingResultsLocation = useApiPagedLocal<ILocation>(propsLocation)

  const propsInspection: IUseApiPagedResultsProps<IInspection> = {
    apiFunction: inspectionRepository.findAll,
    defaultLimit,
    prefix: "INSPECTION_P",
  }
  const pagingResultsInspection = useApiPagedLocal<IInspection>(propsInspection)

  const propsUser: IUseApiPagedResultsProps<IServiceUser> = {
    apiFunction: userRepository.findAll,
    defaultLimit,
    prefix: "USER_P",
  }
  const pagingResultsUser = useApiPagedLocal<IServiceUser>(propsUser)

  const { t } = useTranslation()
  const map = useMap()
  const { currentUser, updateProfile } = useAuth()
  const mapType = currentUser?.user.profile.map_type ?? "satellite"

  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)

  // Inside LocationsMap function component
  const [selectedMarkerLocation, setSelectedMarkerLocation] = useState<ILocation | null>(null)
  const [selectedMarkerInspection, setSelectedMarkerInspection] = useState<IInspection | null>(null)
  const [selectedMarkerUser, setSelectedMarkerUser] = useState<IServiceUser | null>(null)

  const [showInspections, setShowInspections] = useLocalStorage<boolean>("MAP_SHOW_INSPECTIONS", true)
  const [showLocations, setShowLocations] = useLocalStorage<boolean>("MAP_SHOW_LOCATIONS", true)
  const [showUsers, setShowUsers] = useLocalStorage<boolean>("MAP_SHOW_USERS", true)
  const [showWeather, setShowWeather] = useLocalStorage<boolean>("MAP_SHOW_WEATHER", false)

  const handleShowInspections = useCallback(() => {
    setShowInspections(showInspection => !showInspection)
  }, [])

  const handleShowUsers = useCallback(() => {
    setShowUsers(showUsers => !showUsers)
  }, [])

  const handleShowLocations = useCallback(() => {
    setShowLocations(showLocations => !showLocations)
  }, [])

  const handleShowWeather = useCallback(() => {
    setShowWeather(showWeather => !showWeather)
  }, [])

  const handleMapTypeChange = useCallback(async () => {
    if (map?.getMapTypeId() !== undefined && currentUser?.user.profile.id !== undefined) {
      const patch: IProfilePatch = { id: currentUser.user.profile.id, name: "", map_type: map.getMapTypeId() }
      await updateProfile?.(patch)
    }
  }, [map, currentUser])

  const handleSetSelectedMarkerLocation = useCallback(
    (marker: ILocation | null) => () => {
      setSelectedMarkerLocation(marker)
    },
    [],
  )

  const handleSetSelectedMarkerInspection = useCallback(
    (marker: IInspection | null) => () => {
      setSelectedMarkerInspection(marker)
    },
    [],
  )

  const handleSetSelectedMarkerUser = useCallback(
    (marker: IServiceUser | null) => () => {
      setSelectedMarkerUser(marker)
    },
    [],
  )

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  /**
   * Creates and returns a weather layer for a Google Map, displaying cloud coverage tiles from OpenWeatherMap.
   *
   * @returns {any|null} Returns a Google Maps ImageMapType object if the Google Maps API is defined, otherwise returns null.
   */
  const weatherLayer = useMemo((): google.maps.ImageMapType | null => {
    if (appSettings?.serverInfo?.addon_map_weather !== true) {
      return null
    }
    if (window.google?.maps?.ImageMapType !== undefined) {
      const token = currentUser?.token.access
      // noinspection JSUnusedGlobalSymbols
      return new window.google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          return `${BASE_URL}/lookups/open_weather_map?type=clouds_new&zoom=${zoom}&x=${coord.x}&y=${coord.y}&token=${token}`
        },
        tileSize: new window.google.maps.Size(256, 256),
      })
    }
    return null
  }, [window.google?.maps?.ImageMapType, appSettings?.serverInfo?.addon_map_weather])

  useEffect(() => {
    if (weatherLayer !== null) {
      if (map !== null && showWeather === true) {
        const index = map.overlayMapTypes.getArray().indexOf(weatherLayer)
        if (index === -1) {
          map.overlayMapTypes.push(weatherLayer)
        }
      } else if (map !== null && showWeather === false) {
        // Remove weather layer if toggle off
        const index = map.overlayMapTypes.getArray().indexOf(weatherLayer)
        if (index > -1) {
          map.overlayMapTypes.removeAt(index)
        }
      }
    }
  }, [map, weatherLayer, showWeather])

  const locationFiltersUrl = useItemEditDialogUrl(ItemPrefixes.locationFilters, 1)
  const inspectionFiltersUrl = useItemEditDialogUrl(ItemPrefixes.inspectionFilters, 1)
  const serviceUserFiltersUrl = useItemEditDialogUrl(ItemPrefixes.serviceUserFilters, 1)

  return (
    <>
      <ItemViewerDrawer title={t("Location")} prefix={ItemPrefixes.location} infoView={LocationInfo} />
      <ItemViewerDrawer title={t("Service User")} prefix={ItemPrefixes.serviceUser} infoView={ServiceUserInfo} />
      <ItemViewerDrawer title={t("Inspection")} prefix={ItemPrefixes.inspection} infoView={InspectionInfo} />

      {pagingResultsLocation !== undefined && (
        <FiltersData
          pagingResults={pagingResultsLocation}
          openCloseWithUrl={true}
          showButton={false}
          modelName="location"
          openUrlPrefix={ItemPrefixes.locationFilters + "edit"}>
          <FilterSearch />
          <FilterAccount field="accounts" />
          <FilterLocationCity field="city" />
          <FilterLocationStateRegion field="state_region" />
          <FilterLocationCountry field="country" />
        </FiltersData>
      )}

      {pagingResultsInspection !== undefined && (
        <FiltersData
          pagingResults={pagingResultsInspection}
          openCloseWithUrl={true}
          showButton={false}
          modelName="inspection"
          openUrlPrefix={ItemPrefixes.inspectionFilters + "edit"}>
          <FilterSearch />
          <FilterAccount field="account" />
          <FilterLocation field="location" />
          <FilterReportFormat field="report_format" />
          <FilterInspectionType />
          <FilterPriority field="priority" priorityType={PriorityTypes.INSPECTION} />
          <FilterDate field="completed" title="Completed" />
          <FilterVendor field="vendor" />
          <FilterDate field="vendor_due_date" title="Vendor Due Date" />
          <FilterDate field="vendor_site_visit_date" title="Vendor Site Visit Date" />
          <FilterDate field="vendor_submitted_date" title="Vendor Submitted Date" />
          <FilterDate field="vendor_returned_date" title="Vendor Returned Date" />
        </FiltersData>
      )}

      {pagingResultsUser !== undefined && (
        <FiltersData
          pagingResults={pagingResultsUser}
          openCloseWithUrl={true}
          showButton={false}
          modelName="service_user"
          openUrlPrefix={ItemPrefixes.serviceUserFilters + "edit"}>
          <FilterSearch />
          <FilterLocationCity field="city" />
          <FilterLocationStateRegion field="state_region" />
          <FilterLocationCountry field="country" />
          <FilterVendor field="vendor_leads" />
          <FilterVendor field="vendor_members" />
        </FiltersData>
      )}

      <Grid item xs={12}>
        <Box sx={{ position: "absolute", mt: 1.5, ml: 25, zIndex: 900 }}>
          <ButtonGroup ref={anchorRef} variant="contained" color="info">
            <Button onClick={handleToggle}>{t("Map Layers")}</Button>
            <Button size="small" onClick={handleToggle}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>

          <Popper sx={{ zIndex: 900 }} open={open} anchorEl={anchorRef.current} disablePortal>
            <PaperLocal sx={{ p: 0 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <List dense>
                  <ListItem
                    dense
                    secondaryAction={
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        checked={showLocations === true}
                        onChange={handleShowLocations}
                      />
                    }>
                    <ListItemButton dense component={Link} to={locationFiltersUrl}>
                      <ListItemIcon>
                        <Box
                          component="img"
                          src="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                          alt={t("Locations")}
                          sx={{ width: 20 }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={t("Locations")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    dense
                    secondaryAction={
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        checked={showInspections === true}
                        onChange={handleShowInspections}
                      />
                    }>
                    <ListItemButton dense component={Link} to={inspectionFiltersUrl}>
                      <ListItemIcon>
                        <Box
                          component="img"
                          src="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                          alt={t("Inspections")}
                          sx={{ width: 20 }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={t("Inspections")} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem
                    dense
                    secondaryAction={
                      <Checkbox edge="start" tabIndex={-1} checked={showUsers === true} onChange={handleShowUsers} />
                    }>
                    <ListItemButton dense component={Link} to={serviceUserFiltersUrl}>
                      <ListItemIcon>
                        <Box
                          component="img"
                          src="https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                          alt={t("Users")}
                          sx={{ width: 20 }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={t("Users")} />
                    </ListItemButton>
                  </ListItem>
                  {appSettings?.serverInfo?.addon_map_weather === true && (
                    <ListItem
                      dense
                      secondaryAction={
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          checked={showWeather === true}
                          onChange={handleShowWeather}
                        />
                      }>
                      <ListItemButton dense>
                        <ListItemIcon>
                          <CloudIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("Weather")} />
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
              </ClickAwayListener>
            </PaperLocal>
          </Popper>
        </Box>
        <Map
          mapId={DEFAULT_GOOGLE_MAP_ID}
          style={{ flexGrow: "1", height }}
          defaultZoom={zoom}
          mapTypeId={mapType}
          onMapTypeIdChanged={handleMapTypeChange}
          defaultCenter={DEFAULT_CENTER}>
          {showLocations === true &&
            pagingResultsLocation?.data?.results.map((item, index) => {
              return (
                <Marker
                  key={index}
                  icon="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                  position={latLong(item, index)}
                  onClick={handleSetSelectedMarkerLocation(item)}
                />
              )
            })}

          {showInspections === true &&
            pagingResultsInspection?.data?.results.map((item, index) => {
              return (
                <Marker
                  key={index}
                  icon="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                  position={latLong(item.location, index)}
                  onClick={handleSetSelectedMarkerInspection(item)}
                />
              )
            })}

          {showUsers === true &&
            pagingResultsUser?.data?.results.map((item, index) => {
              return (
                <Marker
                  key={index}
                  icon="https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                  position={latLong(item, index)}
                  onClick={handleSetSelectedMarkerUser(item)}
                />
              )
            })}

          {showLocations === true && selectedMarkerLocation !== null && (
            <InfoWindow
              position={latLong(selectedMarkerLocation, 0)}
              onCloseClick={handleSetSelectedMarkerLocation(null)}
              headerContent={t("Location")}>
              <ViewListItems prefix={ItemPrefixes.location}>{selectedMarkerLocation}</ViewListItems>
            </InfoWindow>
          )}

          {showInspections === true && selectedMarkerInspection !== null && (
            <InfoWindow
              position={latLong(selectedMarkerInspection.location, 0)}
              onCloseClick={handleSetSelectedMarkerInspection(null)}
              headerContent={t("Inspection")}>
              <ViewListItems prefix={ItemPrefixes.inspection}>{selectedMarkerInspection}</ViewListItems>
            </InfoWindow>
          )}

          {showUsers === true && selectedMarkerUser !== null && (
            <InfoWindow
              position={latLong(selectedMarkerUser, 0)}
              onCloseClick={handleSetSelectedMarkerUser(null)}
              headerContent={t("Service User")}>
              <ViewListItems prefix={ItemPrefixes.serviceUser}>{selectedMarkerUser}</ViewListItems>
            </InfoWindow>
          )}
        </Map>
      </Grid>
    </>
  )
}

export default LocationsMap
