import React from "react"
import { Container, Grid } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { RestRepository } from "../../repositories/RestRepository"
import FormHeader from "../../components/forms/FormHeader"
import ImpairmentForm from "./components/ImpairmentForm"
import { useApiRead } from "../../hooks/useApiRead"
import useApiEdit, { type IUseApiEditProps } from "../../hooks/useApiEdit"
import { type ILocationImpairment, LOCATION_IMPAIRMENT_ENDPOINT } from "../../models/service/ILocationImpairment"
import { LOCATION_IMPAIRMENT_VIEW_URL } from "../../../apps/admin/config/urls"
import FormBox from "../../components/forms/FormBox"
import FormErrors from "../../components/forms/FormErrors"

const repository = new RestRepository<ILocationImpairment>(LOCATION_IMPAIRMENT_ENDPOINT)
const redirectView = LOCATION_IMPAIRMENT_VIEW_URL

/**
 * This page will allow editing of an locationImpairment.
 *
 * @returns {React.FC} the locationImpairment edit page.
 */
const EditPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const apiRead = useApiRead<ILocationImpairment>({ apiFunction: repository.read })

  const props: IUseApiEditProps<ILocationImpairment> = {
    apiFunction: repository.edit,
    setError: form.setError,
    redirectView,
  }
  const apiEdit = useApiEdit<ILocationImpairment>(props)

  return (
    <Container fixed>
      <FormProvider {...form}>
        <Grid container spacing={2}>
          <FormHeader
            title="Edit Impairment"
            loading={apiEdit.saving || apiRead.loading}
            errors={form.formState.errors}
          />
          <FormErrors apiError={apiRead.error} connectionError={apiEdit.connectionError} />
          <FormBox form={form} onSubmit={apiEdit.handleEdit}>
            <ImpairmentForm locationImpairment={apiRead.data} form={form} isEdit />
          </FormBox>
        </Grid>
      </FormProvider>
    </Container>
  )
}

export default EditPage
