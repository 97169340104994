import React from "react"
import { Container, Grid } from "@mui/material"
import { FormProvider, useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import InspectionForm from "./components/InspectionForm"
import { type IInspection, INSPECTION_ENDPOINT } from "../../../../shared/models/service/IInspection"
import { INSPECTION_VIEW_URL } from "../../config/urls"
import useApiAdd, { type IUseApiAddProps } from "../../../../shared/hooks/useApiAdd"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"

const repository = new RestRepository<IInspection>(INSPECTION_ENDPOINT)
const redirectView = INSPECTION_VIEW_URL

/**
 * This page will allow adding of an inspection.
 *
 * @returns {React.FC} the inspection add page.
 */
const AddPage: React.FC = (): React.ReactElement => {
  const form = useForm()

  const props: IUseApiAddProps<IInspection> = { apiFunction: repository.add, setError: form.setError, redirectView }
  const apiAdd = useApiAdd<IInspection>(props)

  return (
    <Container fixed>
      <FormProvider {...form}>
        <Grid container spacing={2}>
          <FormHeader title="Add Inspection" loading={apiAdd.saving} errors={form.formState.errors} />
          <FormErrors connectionError={apiAdd.connectionError} />
          <FormBox form={form} onSubmit={apiAdd.handleAdd}>
            <InspectionForm form={form} />
          </FormBox>
        </Grid>
      </FormProvider>
    </Container>
  )
}

export default AddPage
