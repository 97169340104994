import React, { useCallback, useContext } from "react"
import RiskWriterProvider from "../risk_writer/context/RiskWriterProvider"
import RiskWriter, { RiskWriterBrief, RiskWriterBuilder } from "../risk_writer/RiskWriter"
import BuildReportDialog from "../risk_writer_support/BuildReportDialog"
import RiskWriterFormView from "../risk_writer/components/RiskWriterFormView"
import ViewLoading from "../ViewLoading"
import { buildApiAction } from "../../hooks/useApiAction"
import { RestRepository } from "../../repositories/RestRepository"
import { type IMainModel } from "../../models/service/IMainModel"
import { type IRiskWriterData } from "../risk_writer/models/IRiskWriterData"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import { ImageChooserContext } from "../files/ImageChooser"
import { type IFile, SUMMARY_FILE_ENDPOINT } from "../../models/service/IFile"
import { useItemEditDialogUrl } from "../item_viewer/ItemViewerDrawer"
import { ItemPrefixes } from "../../../config/config"
import { type IRiskWriteModel } from "../../models/components/IRiskWriteModel"
import { type IRiskWriterFormat } from "../risk_writer/models/IRiskWriterFormat"
import type { IPanariskAppContext } from "../../models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../app/PanariskApp"
import useAuth, { type IProfilePatch } from "../../hooks/useAuth"
import { Box } from "@mui/material"
import { type ISummary, SUMMARY_ENDPOINT } from "../../models/service/ISummary"

const summaryRepository = new RestRepository<IFile>(SUMMARY_ENDPOINT)
const summaryFileRepository = new RestRepository<IFile>(SUMMARY_FILE_ENDPOINT)

interface IReportDataPath extends IMainModel {
  risk_writer_data: IRiskWriterData | null
}

export interface IGeneratedContent extends IMainModel {
  content: string
}

interface IProps {
  data: IRiskWriteModel | ISummary
  reportWriterFormat: IRiskWriterFormat | null | undefined
  title: string
  onReloadReport: () => Promise<void>
  onRefreshTableData?: () => Promise<void>
  enableAddBuilding?: boolean
  repository: RestRepository<IMainModel>
  genContentRepository: RestRepository<IGeneratedContent>
  filesRepository: RestRepository<IFile | IMainModel>
}

/**
 * Component for viewing and editing risk writer data.
 *
 * @param {IProps} props - The props of the component.
 * @returns {React.ReactElement} The rendered component.
 */
const ViewRiskWriterSummary: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const {
    data,
    reportWriterFormat,
    title,
    onReloadReport,
    onRefreshTableData,
    repository,
    genContentRepository,
    filesRepository,
  } = props

  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const units = appSettings?.serverInfo?.units
  const { currentUser, updateProfile, loading: authLoading } = useAuth()

  const { setDrawerOpen, fileSelected } = useContext(ImageChooserContext)
  const axiosRequest = useAxiosRequest()
  const genContentApiAction = buildApiAction<IGeneratedContent>(genContentRepository, data.id)

  const editBriefUrl = useItemEditDialogUrl(ItemPrefixes.summaryBrief, data.id)

  const handleFileRequest = useCallback(() => {
    setDrawerOpen?.(true)
  }, [])

  const handleFileCaptionChange = useCallback(async (file: IFile) => {
    await axiosRequest.callRequest(async () => {
      await (filesRepository as RestRepository<IFile>).patch(file, file.id)
    })
  }, [])

  const handleGetFile = useCallback(async (fileId: number) => {
    let file = null
    await axiosRequest.callRequest(async () => {
      file = await (filesRepository as RestRepository<IFile>).read(fileId)
    })
    return file
  }, [])

  const handleSave = useCallback(
    async (reportWriterData: IRiskWriterData) => {
      if (data?.id !== undefined) {
        await axiosRequest.callRequest(async () => {
          const reportData: IReportDataPath = {
            id: data.id,
            name: data.name,
            risk_writer_data: reportWriterData,
          }
          await repository.patch(reportData, data.id)
        })
      }
    },
    [data],
  )

  const handleGenerateContent = useCallback(async (): Promise<Record<string, string> | null> => {
    if (data?.id !== undefined) {
      const response = await genContentApiAction.callAction<Record<string, string>>("generate_content")
      if (response !== undefined) {
        return response
      }
      return null
    }
    return null
  }, [data?.id])

  const handleAutoSave = useCallback(async () => {
    if (currentUser?.user !== undefined) {
      const profile: IProfilePatch = {
        id: currentUser.user.profile.id,
        name: "",
        auto_save_risk_writer: !currentUser.user.profile.auto_save_risk_writer,
      }
      await updateProfile?.(profile)
    }
  }, [currentUser?.user?.profile.auto_save_risk_writer])

  return (
    <>
      <ViewLoading loading={genContentApiAction.saving || authLoading} />
      <RiskWriterProvider
        units={units}
        autoSave={currentUser?.user?.profile.auto_save_risk_writer}
        onToggleAutoSave={handleAutoSave}
        format={reportWriterFormat}
        data={data.risk_writer_data}
        onGenerateContent={handleGenerateContent}
        onSave={handleSave}
        onFileCaptionChange={handleFileCaptionChange}
        onFileRequest={handleFileRequest}
        onReloadReport={onReloadReport}
        fileSelected={fileSelected}
        getFile={handleGetFile}>
        <RiskWriter
          editBriefUrl={editBriefUrl}
          title={title}
          onClose={onReloadReport}
          onRefreshTableData={onRefreshTableData}>
          <RiskWriterBuilder>
            <BuildReportDialog
              itemId={data.id}
              modelName="summary"
              repository={summaryRepository}
              fileRepository={summaryFileRepository}
            />
          </RiskWriterBuilder>
          <RiskWriterBrief>
            {data.risk_writer_brief_data !== null ? (
              <Box>
                <RiskWriterFormView
                  formType="brief"
                  units={units}
                  format={reportWriterFormat}
                  fileEndpoint={SUMMARY_FILE_ENDPOINT}>
                  {data.risk_writer_brief_data}
                </RiskWriterFormView>
              </Box>
            ) : (
              <></>
            )}
          </RiskWriterBrief>
        </RiskWriter>
      </RiskWriterProvider>
    </>
  )
}

export default ViewRiskWriterSummary
