export const APP_VERSION: string = process.env.REACT_APP_VERSION ?? ""

export const PANARISK_USER_KEY = "PANARISK_USER"
export const PANARISK_DASHBOARD_KEY = "PANARISK_DASHBOARD"
export const PANARISK_APP_SETTINGS_KEY = "PANARISK_APP_SETTINGS_KEY"

export const COMPANY_NAME = "Panarisk"

export const DRAWER_WIDTH = 300

export const DATE_FORMAT = "MM/DD/YYYY"
// export const DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`
// export const DATE_INPUT_FORMAT = "MM/dd/yyyy"
export const DATE_OUTPUT_FORMAT = "yyyy-MM-DD"

export const DEFAULT_GOOGLE_MAP_ID = "6fc24d3517b45521"
export const DEFAULT_CENTER = { lat: 25.9973298, lng: -97.1577839 }


export const CHART_COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#B82E2E", "#6633CC", "#FF33C9"]

export enum PriorityTypes {
  INSPECTION = "INSPECTION",
  RECOMMENDATION = "RECOMMENDATION",
}

export enum SelectOptions {
  UNITS_SYSTEMS = "UNITS_SYSTEMS",
  CURRENCY_CHOICES = "CURRENCY_CHOICES",
  CURRENCY_SYMBOLS = "CURRENCY_SYMBOLS",
  LANGUAGE_CHOICES = "LANGUAGE_CHOICES",
  MAP_TYPES = "MAP_TYPES",
  CHECKED_SELECTED = "CHECKED_SELECTED",
}

export enum ItemPrefixes {
  account = "account-",
  location = "location-",
  locationFilters = "location-filters-",
  reportFormat = "report-format-",
  building = "building-",
  contact = "contact-",
  serviceUser = "service-user-",
  serviceUserFilters = "service-user-filters-",
  inspection = "inspection-",
  inspectionFilters = "inspection-filters-",
  inspectionRec = "inspection-rec-",
  inspectionBrief = "inspection-brief-",
  inspectionBuilding = "inspection-building-",
  inspectionMessage = "inspection-message-",
  impairment = "impairment-",
  vendor = "vendor-",
  summaryBrief = "summary-brief-",
  emailTemplate = "email-template-",
  filterBookmark = "filter-bookmark-",
}

export const ADDON_SUMMARIES = "ADDON_SUMMARIES"
export const ADDON_IMPAIRMENTS = "ADDON_IMPAIRMENTS"
export const ADDON_MAP_WEATHER = "ADDON_MAP_WEATHER"
