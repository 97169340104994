import { type ILocation } from "./ILocation"
import { type IAccount } from "./IAccount"
import { type IServiceUser } from "./IServiceUser"
import { type IListItem } from "../components/IListItem"
import { type IVendor } from "./IVendor"
import { type IBuilding } from "./IBuilding"
import { type IReportFormat, type IReportWriterFormat } from "./IReportFormat"
import { type IRiskWriterData } from "../../components/risk_writer/models/IRiskWriterData"
import { type IPriority } from "./IPriority"
import { type IInspectionType } from "./IInspectionType"
import { type IRiskWriteModel } from "../components/IRiskWriteModel"
import { type TRiskWriterFormData } from "../../components/risk_writer/models/TRiskWriterFormData"
import { type IInspectionStatus } from "./IInspectionStatus"
import { type TMetadataData } from "./IMetadataConfig"
import { type IContact } from "./IContact"

export const INSPECTION_ENDPOINT = "inspections"
export const INSPECTION_FILE_EXT_ENDPOINT = "inspection_file_ext"

export interface IInspection extends IListItem, IRiskWriteModel {
  id: number
  identifier: string
  notes: string

  account: IAccount | null
  location: ILocation
  buildings: IBuilding[]

  inspection_status: IInspectionStatus | null
  inspection_type: IInspectionType | null
  report_format: IReportFormat | null
  report_writer_format: IReportWriterFormat | null
  risk_writer_data: IRiskWriterData | null
  risk_writer_brief_data: TRiskWriterFormData | null

  vendor: IVendor | null
  vendor_lead_as_contact: IContact | null
  vendor_member: IServiceUser | null
  vendor_member_as_contact: IContact | null

  priority: IPriority | null

  fee: number | null

  vendor_due_date: string | null
  vendor_site_visit_date: string | null
  vendor_submitted_date: string | null
  vendor_submitted_by: IServiceUser | null
  vendor_returned_date: string | null

  metadata_data: TMetadataData | null

  completed: string
  completed_by: IServiceUser

  created: string
  updated: string
}

/**
 * Converts a `IReportWriterFormat` object into an `IListItem` object. If the input is `null`, the function returns `null`.
 *
 * @param {IReportWriterFormat | null} reportWriterFormat - The input object containing format details. Can be `null`.
 * @returns {IListItem | null} An object representing the format as a list item, or `null` if the input is `null`.
 */
export const reportWriterFormatAsListItem = (reportWriterFormat: IReportWriterFormat | null): IListItem | null => {
  if (reportWriterFormat !== null) {
    return {
      id: reportWriterFormat.id,
      name: `${reportWriterFormat.title} | ${reportWriterFormat.version}`,
    }
  }
  return null
}
