import React, { useCallback, useState } from "react"
import { Box, Button, Grid } from "@mui/material"
import DrawerRight from "../containers/DrawerRight"
import EditIcon from "@mui/icons-material/Edit"
import { RestRepository } from "../../repositories/RestRepository"
import { type IServiceUser, SERVICE_USER_ENDPOINT } from "../../models/service/IServiceUser"
import { type IListItem } from "../../models/components/IListItem"
import SelectFilteredSingle from "../forms/SelectFilteredSingle"
import { type IVendor } from "../../models/service/IVendor"
import ServiceUserInfo from "../../../apps/admin/pages/service_users/components/ServiceUserInfo"
import { type IInspection, INSPECTION_ENDPOINT } from "../../models/service/IInspection"
import { type IMainModel } from "../../models/service/IMainModel"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import ViewLoading from "../ViewLoading"
import ErrorMessage from "../ErrorMessage"
import { useTranslation } from "react-i18next"

interface IPatch extends IMainModel {
  vendor_member: number | null
}

const serviceUserRepository = new RestRepository<IServiceUser | IListItem>(SERVICE_USER_ENDPOINT)
const inspectRepository = new RestRepository<IPatch>(INSPECTION_ENDPOINT)

interface IProps {
  vendor: IVendor
  inspection: IInspection
  onChange: () => void
}

/**
 * EditInspectionVendorMember is a React Functional Component that renders a form for editing
 * the assigned vendor member for a specific vendor.
 *
 * @param {IProps} props - The properties object containing the vendor information.
 * @returns {React.ReactElement} The rendered component.
 */
const EditInspectionVendorMember: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { vendor, inspection, onChange } = props
  const axiosRequest = useAxiosRequest()
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  const [vendorMember, setVendorMember] = useState<IServiceUser | IListItem | null>(null)

  const handleAssign = useCallback(async () => {
    await axiosRequest.callRequest(async () => {
      const memberId = vendorMember?.id !== undefined ? Number(vendorMember.id) : null
      const patch: IPatch = { id: inspection.id, name: inspection.name, vendor_member: memberId }
      await inspectRepository.patch(patch, inspection.id)
      onChange()
      setOpen(open => !open)
      setVendorMember(null)
    })
  }, [inspection, vendorMember])

  const handleClear = useCallback(async () => {
    await axiosRequest.callRequest(async () => {
      const patch: IPatch = { id: inspection.id, name: inspection.name, vendor_member: null }
      await inspectRepository.patch(patch, inspection.id)
      onChange()
      setOpen(open => !open)
      setVendorMember(null)
    })
  }, [inspection, vendorMember])

  return (
    <DrawerRight icon={<EditIcon />} title={t("Edit Assigned")} showIconButton closeDrawer={open}>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ErrorMessage error={axiosRequest.errorMessage} />
          </Grid>
          <Grid item xs={12}>
            {t(`From ${vendor.name}, assign inspection to`)}:
          </Grid>
          <Grid item xs={12}>
            <SelectFilteredSingle
              name="assign"
              defaultValue={vendorMember}
              filters={[{ field: "vendor_members", value: vendor.id }]}
              repository={serviceUserRepository}
              onChange={setVendorMember}
            />
          </Grid>
          <Grid item xs={6}>
            <Button onClick={handleClear} disabled={axiosRequest.loading}>
              {t("Clear")}
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Button onClick={handleAssign} disabled={axiosRequest.loading || vendorMember === null}>
              {t("Assign")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ViewLoading loading={axiosRequest.loading} inline />
          </Grid>
          <Grid item xs={12}>
            {vendorMember !== null && <ServiceUserInfo infoId={vendorMember.id} />}
          </Grid>
        </Grid>
      </Box>
    </DrawerRight>
  )
}

export default EditInspectionVendorMember
