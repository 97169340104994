import React, { useContext } from "react"
import { PanariskAppContext } from "../../../app/PanariskApp"
import { Grid } from "@mui/material"
import FhMuiRichTextField from "../forms/FhMuiRichTextField"
import { MetadataInputType, type TModelNames } from "../../models/service/IMetadataConfig"
import type { UseFormReturn } from "react-hook-form/dist/types"
import FhMuiTextField from "../forms/FhMuiTextField"
import FhMuiCheckboxField from "../forms/FhMuiCheckboxField"
import FhMuiDateField from "../forms/FhMuiDateField"

interface IProps {
  modelName: TModelNames
  form: UseFormReturn
}

/**
 * Renders a metadata form based on the provided model metadata configuration.
 *
 * @param {IProps} props - The properties object containing necessary parameters.
 * @param {TModelNames} props.modelName - The name of the model used to fetch metadata configuration.
 * @param {object} props.form - The form object to handle control and state management.
 * @returns {React.ReactElement} A React element containing a form grid populated with fields
 *                               based on metadata configuration.
 */
const MetadataForm: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { modelName, form } = props
  const { appSettings } = useContext(PanariskAppContext)
  const metadata = appSettings?.serverInfo?.[`${modelName}_metadata_config`]

  return (
    <Grid container spacing={2}>
      {metadata?.fields.map(field => {
        const fieldName = `metadata_data.${field.name}`
        return (
          <Grid key={field.name} item xs={12} lg={field.cols ?? 12}>
            {field.input_type === MetadataInputType.RICH_TEXT && (
              <FhMuiRichTextField control={form.control} name={fieldName} label={field.title} />
            )}
            {field.input_type === MetadataInputType.TEXT && (
              <FhMuiTextField control={form.control} name={fieldName} label={field.title} />
            )}
            {field.input_type === MetadataInputType.CHECKBOX && (
              <FhMuiCheckboxField control={form.control} name={fieldName} label={field.title} />
            )}
            {field.input_type === MetadataInputType.DATE && (
              <FhMuiDateField control={form.control} name={fieldName} label={field.title} />
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default MetadataForm
