import React, { useCallback, useState } from "react"
import DrawerRight from "../containers/DrawerRight"
import { Box, Button, Grid } from "@mui/material"
import SelectFilteredSingle from "../forms/SelectFilteredSingle"
import EmailTemplateInfo from "../../../apps/admin/pages/email_templates/components/EmailTemplateInfo"
import { useTranslation } from "react-i18next"
import type { IListItem } from "../../models/components/IListItem"
import { RestRepository } from "../../repositories/RestRepository"
import { EMAIL_TEMPLATE_ENDPOINT, type IEmailTemplate } from "../../models/service/IEmailTemplate"

const emailTemplateRepository = new RestRepository<IEmailTemplate | IListItem>(EMAIL_TEMPLATE_ENDPOINT)

interface IProps {
  onChange: (emailTemplateListItem: IListItem | null) => Promise<void>
}


/**
 * Renders the EmailTemplateDrawer component, allowing users to select and apply an email template.
 *
 * @param {IProps} props - The properties object containing the necessary parameters for the component.
 * @returns {React.ReactElement} The rendered EmailTemplateDrawer component.
 */
const EmailTemplateDrawer: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { onChange } = props
  const [emailTemplateListItem, setEmailTemplateListItem] = useState<IListItem | null>(null)
  const { t } = useTranslation()

  const handleUseEmailTemplate = useCallback(async () => {
    await onChange(emailTemplateListItem)
  }, [emailTemplateListItem, onChange])

  return (
    <DrawerRight title={t("Email Templates")}>
      <Box>
        <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
          <Grid item xs={12}>
            <SelectFilteredSingle
              name="email_template"
              defaultValue={emailTemplateListItem}
              repository={emailTemplateRepository}
              onChange={setEmailTemplateListItem}
            />
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button onClick={handleUseEmailTemplate} disabled={emailTemplateListItem === null}>
              {t("Use Email Template")}
            </Button>
          </Grid>
        </Grid>
        {emailTemplateListItem?.id !== undefined && <EmailTemplateInfo infoId={emailTemplateListItem.id} />}
      </Box>
    </DrawerRight>
  )
}

export default EmailTemplateDrawer