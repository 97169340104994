import React from "react"
import { Container, Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import AccountForm from "./components/AccountForm"
import { ACCOUNT_ENDPOINT, type IAccount } from "../../../../shared/models/service/IAccount"
import { ACCOUNT_VIEW_URL } from "../../config/urls"
import useApiAdd, { type IUseApiAddProps } from "../../../../shared/hooks/useApiAdd"
import FormHeader from "../../../../shared/components/forms/FormHeader"
import FormBox from "../../../../shared/components/forms/FormBox"
import FormErrors from "../../../../shared/components/forms/FormErrors"
import { useTranslation } from "react-i18next"

const repository = new RestRepository<IAccount>(ACCOUNT_ENDPOINT)
const redirectView = ACCOUNT_VIEW_URL

/**
 * This page will allow adding of an account.
 *
 * @returns {React.FC} the account add page.
 */
const AddPage: React.FC = (): React.ReactElement => {
  const form = useForm()
  const { t } =  useTranslation()

  const props: IUseApiAddProps<IAccount> = { apiFunction: repository.add, setError: form.setError, redirectView }
  const apiAdd = useApiAdd<IAccount>(props)

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <FormHeader title={t("Add Account")} loading={apiAdd.saving} errors={form.formState.errors} />
        <FormErrors connectionError={apiAdd.connectionError} />
        <FormBox form={form} onSubmit={apiAdd.handleAdd}>
          <AccountForm form={form} />
        </FormBox>
      </Grid>
    </Container>
  )
}

export default AddPage
