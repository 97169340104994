import React from "react"
import {
  type IInspectionRecommendation,
  INSPECTION_RECOMMENDATION_ENDPOINT,
} from "../../../../shared/models/service/IInspectionRecommendation"
import { RestRepository } from "../../../../shared/repositories/RestRepository"
import { type IUseApiPagedResultsProps, useApiPagedLocal } from "../../../../shared/hooks/useApiPagedLocal"
import ItemViewerDrawer from "../../../../shared/components/item_viewer/ItemViewerDrawer"
import { ItemPrefixes, PriorityTypes } from "../../../../config/config"
import LocationInfo from "../../../admin/pages/locations/components/LocationInfo"
import { useTranslation } from "react-i18next"
import { Grid, TableBody, TableHead, TableRow } from "@mui/material"
import PageHeader from "../../../../shared/components/pages/PageHeader"
import { INSPECTION_RECOMMENDATION_VIEW_URL } from "../../../admin/config/urls"
import TableData from "../../../../shared/components/tables/TableData"
import FiltersData from "../../../../shared/components/filters/FiltersData"
import FilterSearch from "../../../../shared/components/filters/FilterSearch"
import FilterLocation from "../../../../shared/components/filters/FilterLocation"
import FilterPriority from "../../../../shared/components/filters/FilterPriority"
import FilterInspection from "../../../../shared/components/filters/FilterInspection"
import FilterRecommendationType from "../../../../shared/components/filters/FilterRecommendationType"
import FilterRecommendationStatus from "../../../../shared/components/filters/FilterRecommendationStatus"
import FilterRiskScoreCategory from "../../../../shared/components/filters/FilterRiskScoreCategory"
import FilterDate from "../../../../shared/components/filters/FilterDate"
import FilterServiceUser from "../../../../shared/components/filters/FilterServiceUser"
import TableCellData from "../../../../shared/components/tables/TableCellData"
import TableCellCenter from "../../../../shared/components/tables/TableCellCenter"
import TableCellLocation from "../../../../shared/components/tables/TableCellLocation"
import TableCellTruncate from "../../../../shared/components/tables/TableCellTruncate"
import TableCellDate from "../../../../shared/components/tables/TableCellDate"
import ListData from "../../../../shared/components/lists/ListData"
import ListDataItem from "../../../../shared/components/lists/ListDataItem"
import ListProperty from "../../../../shared/components/lists/ListProperty"
import type { IListItem } from "../../../../shared/models/components/IListItem"
import {
  useMetadataTableFilter,
  useMetadataTableHeader,
  useMetadataTableRow,
} from "../../../../shared/components/metadata/UseMetadataTable"

const repository = new RestRepository<IInspectionRecommendation>(INSPECTION_RECOMMENDATION_ENDPOINT)
const gotoRepo = new RestRepository<IListItem>(INSPECTION_RECOMMENDATION_ENDPOINT)

const modelName = "inspection_recommendation"

/**
 * Renders the index page of the application.
 *
 * @returns {React.ReactElement} - The rendered index page component.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const props: IUseApiPagedResultsProps<IInspectionRecommendation> = { apiFunction: repository.findAll }
  const pagingResults = useApiPagedLocal<IInspectionRecommendation>(props)
  const { data } = pagingResults
  const { t } = useTranslation()

  const metadataTableHeader = useMetadataTableHeader(modelName, pagingResults)
  const metadataTableRow = useMetadataTableRow(modelName)
  const metadataTableFilters = useMetadataTableFilter(modelName)

  return (
    <>
      <ItemViewerDrawer title={t("Location")} prefix={ItemPrefixes.location} infoView={LocationInfo} />
      <Grid container alignItems="center" spacing={2}>
        <PageHeader title="Inspection Rec" titlePlural="Inspection Recs" />
        {pagingResults !== undefined && (
          <>
            <TableData
              pagingResults={pagingResults}
              to={INSPECTION_RECOMMENDATION_VIEW_URL}
              toInfoPrefix={ItemPrefixes.inspectionRec}
              repository={gotoRepo}
              storageSuffix="inspection/recommendations">
              <FiltersData pagingResults={pagingResults}>
                <FilterSearch />
                <FilterLocation field="inspection__location" />
                <FilterPriority field="priority" priorityType={PriorityTypes.RECOMMENDATION} />
                <FilterInspection field="inspection" />
                <FilterRecommendationType field="type" />
                <FilterRecommendationStatus field="status" />
                <FilterRiskScoreCategory field="risk_score_category" />
                {metadataTableFilters()}
                <FilterDate field="completed" title="Completed" />
                <FilterServiceUser field="completed_by" title="Completed By" />
              </FiltersData>
              <TableHead>
                <TableCellData field="identifier">Identifier</TableCellData>
                <TableCellData field="location" pagingResults={pagingResults}>
                  Location
                </TableCellData>
                <TableCellData field="inspection" pagingResults={pagingResults}>
                  Inspection
                </TableCellData>
                <TableCellData field="title">Title</TableCellData>
                <TableCellData field="body">Body</TableCellData>
                <TableCellData field="status" pagingResults={pagingResults}>
                  Status
                </TableCellData>
                <TableCellData field="type" pagingResults={pagingResults}>
                  Type
                </TableCellData>
                <TableCellData field="priority" pagingResults={pagingResults}>
                  Priority
                </TableCellData>
                <TableCellData field="risk_score_category" pagingResults={pagingResults}>
                  Risk Score Category
                </TableCellData>
                {metadataTableHeader()}
                <TableCellCenter field="completed" pagingResults={pagingResults}>
                  Completed
                </TableCellCenter>
                <TableCellCenter field="completed_by" pagingResults={pagingResults}>
                  Completed By
                </TableCellCenter>
              </TableHead>
              <TableBody>
                {data?.results.map((item: IInspectionRecommendation) => (
                  <TableRow key={item.id}>
                    <TableCellData field="identifier">{item.identifier}</TableCellData>
                    <TableCellLocation field="location">{item.location}</TableCellLocation>
                    <TableCellTruncate field="inspection">{item.inspection.name}</TableCellTruncate>
                    <TableCellTruncate field="title">{item.title}</TableCellTruncate>
                    <TableCellTruncate field="body">{item.body}</TableCellTruncate>
                    <TableCellData field="status">{item.status?.name}</TableCellData>
                    <TableCellData field="type">{item.type?.name}</TableCellData>
                    <TableCellData field="priority">{item.priority?.name}</TableCellData>
                    <TableCellData field="risk_score_category">{item.risk_score_category?.name}</TableCellData>
                    {metadataTableRow(item.metadata_data)}
                    <TableCellDate field="completed">{item.completed}</TableCellDate>
                    <TableCellData field="completed_by">{item.completed_by?.name}</TableCellData>
                  </TableRow>
                ))}
              </TableBody>
            </TableData>

            <ListData pagingResults={pagingResults}>
              {data?.results.map((item: IInspectionRecommendation) => (
                <ListDataItem key={item.id} to={`${INSPECTION_RECOMMENDATION_VIEW_URL}/${item.id}`} title={item.title}>
                  <ListProperty label="Status">{item.status?.name}</ListProperty>
                  <ListProperty label="Type">{item.type?.name}</ListProperty>
                  <ListProperty label="Priority">{item.priority?.name}</ListProperty>
                </ListDataItem>
              ))}
            </ListData>
          </>
        )}
      </Grid>
    </>
  )
}

export default IndexPage
