import React, { useCallback, useState } from "react"
import { Box, Button, Grid } from "@mui/material"
import DrawerRight from "../containers/DrawerRight"
import EditIcon from "@mui/icons-material/Edit"
import { RestRepository } from "../../repositories/RestRepository"
import { type IListItem } from "../../models/components/IListItem"
import SelectFilteredSingle from "../forms/SelectFilteredSingle"
import { type IVendor, VENDOR_ENDPOINT } from "../../models/service/IVendor"
import { type IInspection, INSPECTION_ENDPOINT } from "../../models/service/IInspection"
import { type IMainModel } from "../../models/service/IMainModel"
import { useAxiosRequest } from "../../hooks/useAxiosRequest"
import ViewLoading from "../ViewLoading"
import ErrorMessage from "../ErrorMessage"
import VendorInfo from "../../../apps/admin/pages/vendors/components/VendorInfo"
import { useTranslation } from "react-i18next"

interface IPatch extends IMainModel {
  vendor: number | null
  vendor_member: null
}

const vendorRepository = new RestRepository<IVendor | IListItem>(VENDOR_ENDPOINT)
const inspectRepository = new RestRepository<IPatch>(INSPECTION_ENDPOINT)

interface IProps {
  inspection: IInspection
  onChange: () => void
}

/**
 * Renders the EditInspectionVendor component used for assigning a vendor to an inspection.
 *
 * @param {IProps} props - The props for the component.
 * @param {IInspection} props.inspection - The current inspection details.
 * @param {Function} props.onChange - Callback function triggered after a successful update.
 * @returns {React.ReactElement} The rendered component.
 */
const EditInspectionVendor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { inspection, onChange } = props
  const axiosRequest = useAxiosRequest()
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const [vendor, setVendor] = useState<IVendor | IListItem | null>(null)

  const handleAssign = useCallback(async () => {
    await axiosRequest.callRequest(async () => {
      const vendorId = vendor?.id !== undefined ? Number(vendor.id) : null
      const patch: IPatch = { id: inspection.id, name: inspection.name, vendor: vendorId, vendor_member: null }
      await inspectRepository.patch(patch, inspection.id)
      onChange()
      setOpen(open => !open)
      setVendor(null)
    })
  }, [inspection, vendor])

  const handleClear = useCallback(async () => {
    await axiosRequest.callRequest(async () => {
      const patch: IPatch = { id: inspection.id, name: inspection.name, vendor: null, vendor_member: null }
      await inspectRepository.patch(patch, inspection.id)
      onChange()
      setOpen(open => !open)
      setVendor(null)
    })
  }, [inspection, vendor])

  return (
    <DrawerRight icon={<EditIcon />} title={t("Edit Vendor")} showIconButton closeDrawer={open}>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ErrorMessage error={axiosRequest.errorMessage} />
          </Grid>
          <Grid item xs={12}>
            <SelectFilteredSingle
              name="assign"
              defaultValue={vendor}
              repository={vendorRepository}
              onChange={setVendor}
            />
          </Grid>
          <Grid item xs={6}>
            <Button onClick={handleClear} disabled={axiosRequest.loading}>
              {t("Clear")}
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Button onClick={handleAssign} disabled={axiosRequest.loading || vendor === null}>
              {t("Assign")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ViewLoading loading={axiosRequest.loading} inline />
          </Grid>
          <Grid item xs={12}>
            {vendor !== null && <VendorInfo infoId={vendor.id} />}
          </Grid>
        </Grid>
      </Box>
    </DrawerRight>
  )
}

export default EditInspectionVendor
