import { type IListItem } from "../components/IListItem"
import { type IPaging } from "../components/IPaging"

export const FILTER_BOOKMARK_ENDPOINT = "filter_bookmarks"

export type TFilterBookmarkModelNames =
  | "inspection"
  | "vendor"
  | "account"
  | "building"
  | "location"
  | "service_user"
  | "inspection_recommendation"
  | "location_impairment"
  | "policy"

export interface IFilterBookmark extends IListItem {
  id: number
  name: string
  model_name: string
  notes: string
  paging: IPaging
  created: string
  updated: string
}
