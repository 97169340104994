import React from "react"
import {
  type IInspectionMessage,
  INSPECTION_MESSAGE_ENDPOINT,
} from "../../../../../shared/models/service/IInspectionMessage"
import { Grid } from "@mui/material"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { useApiRead } from "../../../../../shared/hooks/useApiRead"
import ViewProperty from "../../../../../shared/components/ViewProperty"
import ViewRichText from "../../../../../shared/components/display/ViewRichText"
import { type IInfoViewProps } from "../../../../../shared/components/item_viewer/ItemViewerDrawer"
import ItemViewerTitle from "../../../../../shared/components/item_viewer/ItemViewerTitle"
import TruncateText from "../../../../../shared/components/TruncateText"
import { useTranslation } from "react-i18next"
import ViewListItems from "../../../../../shared/components/display/ViewListItems"

const repository = new RestRepository<IInspectionMessage>(INSPECTION_MESSAGE_ENDPOINT)

/**
 * Renders a view page for an inspection report message.
 *
 * @param {IInfoViewProps} props See IInfoViewProps for more info
 * @returns {React.ReactElement} The rendered view page.
 */
const InspectionMessageInfo: React.FC<IInfoViewProps> = (props: IInfoViewProps): React.ReactElement => {
  const { infoId } = props
  const apiRead = useApiRead<IInspectionMessage>({ apiFunction: repository.read, itemId: infoId })
  const { data } = apiRead
  const { t } = useTranslation()

  return (
    <>
      {data !== undefined && (
        <>
          <Grid container spacing={2}>
            <Grid item xs>
              <ItemViewerTitle>
                <TruncateText>{data.subject}</TruncateText>
              </ItemViewerTitle>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("To Contacts")}>
                <ViewListItems>{data.to_contacts}</ViewListItems>
              </ViewProperty>
            </Grid>
            <Grid item xs={12}>
              <ViewProperty label={t("Content")}>
                <ViewRichText>{data.content}</ViewRichText>
              </ViewProperty>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default InspectionMessageInfo
