import React, { useCallback, useContext, useState } from "react"
import { type IInspection } from "../../../../../shared/models/service/IInspection"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import DialogControls from "../../../../../shared/components/DialogControls"
import { useTranslation } from "react-i18next"
import FormBox from "../../../../../shared/components/forms/FormBox"
import { useForm } from "react-hook-form"
import {
  type IInspectionMessage,
  INSPECTION_MESSAGE_ENDPOINT,
} from "../../../../../shared/models/service/IInspectionMessage"
import { RestRepository } from "../../../../../shared/repositories/RestRepository"
import { CONTACT_ENDPOINT, type IContact } from "../../../../../shared/models/service/IContact"
import type { IListItem } from "../../../../../shared/models/components/IListItem"
import SelectFilteredMultiple, {
  useSelectFilteredMultiple,
} from "../../../../../shared/components/forms/SelectFilteredMultiple"
import { ItemPrefixes } from "../../../../../config/config"
import FhMuiRichTextField from "../../../../../shared/components/forms/FhMuiRichTextField"
import FhMuiTextField from "../../../../../shared/components/forms/FhMuiTextField"
import { useAxiosRequest } from "../../../../../shared/hooks/useAxiosRequest"
import type { IPanariskAppContext } from "../../../../../shared/models/app/IPanariskAppContext"
import { PanariskAppContext } from "../../../../../app/PanariskApp"
import { EMAIL_TEMPLATE_ENDPOINT, type IEmailTemplate } from "../../../../../shared/models/service/IEmailTemplate"
import useApiAdd, { type IUseApiAddProps } from "../../../../../shared/hooks/useApiAdd"
import EmailTemplateDrawer from "../../../../../shared/components/tools/EmailTemplateDrawer"

const repository = new RestRepository<IInspectionMessage>(INSPECTION_MESSAGE_ENDPOINT)
const contactRepository = new RestRepository<IContact | IListItem>(CONTACT_ENDPOINT)
const emailTemplateRepository = new RestRepository<IEmailTemplate | IListItem>(EMAIL_TEMPLATE_ENDPOINT)

interface IProps {
  inspection: IInspection
  emailTemplate: IEmailTemplate | null | undefined
  title: string
  onComplete?: () => Promise<void>
  initialContacts?: IContact[] | IListItem[]
}

/**
 * A component that provides functionality to share an inspection by email.
 *
 * @param {IProps} props - The properties object containing data related to the inspection.
 * @returns {React.ReactElement} A React element consisting of a button to open a dialog and the dialog interface for
 * sending inspection-related emails.
 */
const InspectionMessage: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { inspection, emailTemplate, title, initialContacts, onComplete } = props
  const form = useForm()

  const propsApiAdd: IUseApiAddProps<IInspectionMessage> = { apiFunction: repository.add, setError: form.setError, redirect: false }
  const apiAdd = useApiAdd<IInspectionMessage>(propsApiAdd)

  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [contacts, setContacts] = useSelectFilteredMultiple("to_contacts", form)
  const { appSettings } = useContext<IPanariskAppContext>(PanariskAppContext)
  const settingId = appSettings.serverInfo?.setting_id

  const axiosRequest = useAxiosRequest()

  const handleOpen = useCallback(async () => {
    setOpen(true)
    if (settingId !== undefined) {
      await axiosRequest.callRequest(async () => {
        const templateId = emailTemplate?.id
        if (templateId !== undefined) {
          const emailTemplate = await emailTemplateRepository.read(templateId) as IEmailTemplate
          form.setValue("subject", emailTemplate.subject)
          form.setValue("content", emailTemplate.content)
        }
      })
    }
    if (initialContacts !== undefined) {
      setContacts(initialContacts)
    }
  }, [settingId, emailTemplate, initialContacts])

  const handleUseEmailTemplate = useCallback(async (emailTemplateListItem: IListItem | null) => {
    if (emailTemplateListItem !== null) {
      await axiosRequest.callRequest(async () => {
        const emailTemplate = (await emailTemplateRepository.read(emailTemplateListItem.id)) as IEmailTemplate
        form.setValue("subject", emailTemplate.subject)
        form.setValue("content", emailTemplate.content)
      })
    }
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleSendEmail = useCallback(async () => {
    const formValue = form.getValues() as IInspectionMessage
    const inspectionMessage = { ...formValue, inspection: inspection.id } satisfies IInspectionMessage
    setOpen(false)
    const newInspectionMessage = await apiAdd.handleAdd(inspectionMessage)
    if (newInspectionMessage !== undefined) {
      await axiosRequest.callRequest(async () => {
        await repository.action(newInspectionMessage.id, "send")
        await onComplete?.()
      })
    }
  }, [form, inspection])

  const handleFormSubmit = useCallback(async (_data: IInspectionMessage) => {
    await handleSendEmail()
  }, [handleSendEmail])

  return (
    <>
      <Button onClick={handleOpen}>{title}</Button>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>{t(`${title} Inspection`)}</DialogTitle>
        <DialogContent>
          <FormBox form={form} onSubmit={handleFormSubmit} showActionPanel={false}>
            <Grid container spacing={2}>
              <Grid item xs />
              <Grid item>
                <EmailTemplateDrawer onChange={handleUseEmailTemplate} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectFilteredMultiple
                  name="to"
                  defaultValue={contacts}
                  repository={contactRepository}
                  onChange={setContacts}
                  itemViewPrefix={ItemPrefixes.contact}
                />
              </Grid>
              <Grid item xs={12}>
                <FhMuiTextField control={form.control} name="subject" />
              </Grid>
              <Grid item xs={12}>
                <FhMuiRichTextField control={form.control} name="content" />
              </Grid>
            </Grid>
          </FormBox>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleSendEmail} onCancel={handleClose} buttonLabel={title} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InspectionMessage
